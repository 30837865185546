import React from "react";
import style from "./EmptyState.module.css";
import CustomButton from "../../../../components/helpers/CustomButton/CustomButton";
import emptyDocs from "../../../../assets/images/comms.svg";

const EmptyStateSms = ({ toggleSmsModal }) => {
  return (
    <div className={style.emptyState}>
      <img className={style.element} alt="Element" src={emptyDocs} />
      <div className={style.text}>
        <div className={style.div}>
          <div className={style.textWrapper}>No messages sent</div>
          <p className={style.onceThePatient}>
            Once the patient a message, it will appear here.
          </p>
        </div>
        <CustomButton
          buttonClassName={style.uploadButton}
          titleClassName={style.uploadButtonTitle}
          buttonHandle={toggleSmsModal}
          title={"Send a sms"}
          icon={false}
        />
      </div>
    </div>
  );
};

export default EmptyStateSms;
