import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useSelector } from "react-redux";

export const usePatientSmsHistory = (patientId) => {
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["patientSmsHistory", tenantVal, patientId],
    queryFn: async () => {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/smsmessage/getbypatientid/${patientId}`
      );
      return response.data;
    },
    enabled: !!tenantVal,
  });
};
