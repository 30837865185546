import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { LOG_URL, WHO_AM_I } from "../../../api/Service";
import axios from "../../../api/axios";
import { useDispatch } from "react-redux";
import { login } from "../../../redux/auth/AuthSlice";
import jwt_decode from "jwt-decode";
import { createAxiosInstance } from "../../../api/axiosConfig";
import s from "./LogIn.module.css";
import AuthLayout from "./AuthLayout/AuthLayout";
import { OutlinedInput } from "@mui/material";
import badicon from "../../../assets/images/badicon.svg";
import PasswordInput from "./components/PasswordInput";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  password: Yup.string()
    .max(255)
    .min(6, "Password must be at least 6 characters long")
    .required("Password is required"),
});

const LogIn = () => {
  const [errMsg, setErrMsg] = useState("");

  const [status, setStatus] = useState(undefined);

  const initialValues = { email: "", password: "" };

  // For Navigation
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchProfileInfo = async () => {
    try {
      const axiosInstance = createAxiosInstance(true);
      const response = await axiosInstance.get(`${WHO_AM_I}`);
      if (response && response.data) {
        if (response.data) {
          let name =
            response && response.data && response.data.staffMember
              ? response.data.staffMember.firstName +
                " " +
                response.data.staffMember.lastName
              : null;
          return name;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    try {
      const response = await axios.post(LOG_URL, values, {
        headers: {
          Accept: "*",
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const { access_token, userId } = response.data;
        const decodetoken = () => {
          try {
            const decodedValues = jwt_decode(access_token);
            return decodedValues;
          } catch (error) {
            console.error("Error decoding token:", error);
          }
        };
        const decodedToken = await decodetoken();

        if (response.status === 200) {
          navigate("/allied_home");
        }
        sessionStorage.setItem("accessToken", response.data.access_token);
        sessionStorage.setItem("refreshToken", response.data.refresh_token);
        sessionStorage.setItem("userId", response.data.userId);
        sessionStorage.setItem("requestId", response.data.requestId);

        localStorage.setItem("kinesin-accessToken", response.data.access_token);
        localStorage.setItem(
          "kinesin-refreshToken",
          response.data.refresh_token
        );
        localStorage.setItem("kinesin-userId", response.data.userId);
        localStorage.setItem("kinesin-requestId", response.data.requestId);
        localStorage.setItem("kinesin-vishvas", decodedToken.tenant);
        localStorage.setItem(
          "kinesin-user",
          JSON.stringify({
            user: {
              id: userId,
              role: decodedToken.role,
              permissions: decodedToken.permissions,
              name: decodedToken.name,
              tenant: decodedToken.tenant,
              type: decodedToken.tenantType,
            },
          })
        );
        localStorage.setItem("kinesin-tenant", decodedToken.tenant);
        const KinesanName = await fetchProfileInfo();
        localStorage.setItem("kinesin-name", KinesanName);
        dispatch(
          login({
            user: {
              id: userId,
              role: decodedToken.role,
              permissions: decodedToken.permissions,
              name: decodedToken.name,
              tenant: decodedToken.tenant,
              type: decodedToken.tenantType,
            },
          })
        );
      } else {
        setErrMsg(response.data.errorMessage);
      }
    } catch (error) {
      setStatus({ type: "error", error });
      if (!error?.response) {
        setErrMsg("No Server Response");
      } else if (error.response?.status === 400) {
        setErrMsg("Missing Email or Password");
      } else if (error.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Invalid username / password");
      }
      console.error(error);
      const { message } = error.response.data;
      setFieldError("password", message);
    }
    setSubmitting(false);
  };

  return (
    <AuthLayout>
      <div className={s.container}>
        <div className={s.textContainer}>
          <h1 className={s.title}>Welcome back to Kinesin!</h1>

          <div className={s.secondaryTextContainer}>
            <p>Login to your account.</p>
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form className={s.form}>
              {status?.type === "error" && (
                <div className="badiconbox">
                  <div className="badiconimg">
                    <img src={badicon} alt="" />
                  </div>
                  <h6>{errMsg}</h6>
                </div>
              )}

              <div className={s.inputsContainer}>
                <div className={s.inputWrapper}>
                  <label className={s.label}>Username / Email</label>
                  <Field
                    as={OutlinedInput}
                    sx={{
                      background: "white",
                      borderRadius: "12px",
                      height: "48px",
                      "&.Mui-focused": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#006546",
                        },
                      },
                    }}
                    name="email"
                    type="email"
                  />
                  {errors.email && touched.email && (
                    <div className="errorstext">{errors.email}</div>
                  )}
                </div>

                <div className={s.inputWrapper}>
                  <label className={s.label} htmlFor="password">
                    Password
                  </label>
                  <PasswordInput id="password" name="password" />

                  {errors.password && touched.password && (
                    <div className="errorstext">{errors.password}</div>
                  )}

                  <Link className={s.forgotPassword} to="/forgotpassword">
                    Forgot your password?
                  </Link>
                </div>
              </div>

              <button
                className={s.submitBtn}
                type="submit"
                disabled={isSubmitting}
              >
                Login
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </AuthLayout>
  );
};

export default LogIn;
