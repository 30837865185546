import React, { useState } from "react";
import NavigationBar from "../../PatientDetailGp/components/NavigationBar/NavigationBar.jsx";
import s from "./PatientConsult.module.css";
import ConsultationEdit from "./components/ConsultationEdit/ConsultationEdit.jsx";
import PatientDetailCardWithOverview from "../../../../components/PatientDetailCardWithOverview/PatientDetailCardWithOverview.jsx";
import { ConsultationBox } from "./components/ConsultationBox/ConsultationBox.jsx";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  usePatientData,
  useStaffInfo,
  useActiveConsultation,
} from "../../../../hooks";
import { Toaster } from "react-hot-toast";
import { AddPrescriptionForm } from "../../../../components/AddPrescriptionForm/AddPrescriptionForm.jsx";
import { AddLetterForm } from "../../../../components/AddLetterForm/AddLetterForm.jsx";
import { AddReferralForm } from "../../../../components/AddReferralForm/AddReferralForm.jsx";
import { useActiveConsultationReferral } from "../../../../hooks/useActiveConsultationReferral.js";
import { useActiveConsultationLetter } from "../../../../hooks/useActiveConsultationLetter.js";
import { useActiveConsultationPrescription } from "../../../../hooks/useActiveConsultationPrescription.js";
import moment from "moment";

const PatientConsult = () => {
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const [tab, setTab] = useState("add-note");

  const { data: staffInfo } = useStaffInfo();
  const { data: patient } = usePatientData();
  const { data: activeConsultation } = useActiveConsultation(
    patient?.activeConsultationId
  );
  const { data: activeConsultationReferral } = useActiveConsultationReferral(
    patient?.activeConsultationId
  );
  const { data: activeConsultationLetter } = useActiveConsultationLetter(
    patient?.activeConsultationId
  );
  const { data: activeConsultationPrescriptionData } =
    useActiveConsultationPrescription(patient?.activeConsultationId);
  const activeConsultationPrescription =
    activeConsultationPrescriptionData?.at(0) || {};

  const staffName = staffInfo?.staffMember
    ? `${staffInfo.staffMember.firstName} ${staffInfo.staffMember.lastName}`
    : "";

  return (
    <>
      <NavigationBar />
      <div style={{ margin: "0 auto" }}>
        <div className={s.wrapper}>
          <div className={s.firstRowWrapper}>
            <div className={s.firstColumnWrapper}>
              <PatientDetailCardWithOverview />
            </div>
            <div className={s.secondColumnWrapper}>
              <div className={s.secondColumnSubWrapper}>
                <ConsultationBox
                  patient={patient}
                  staffName={staffName}
                  consultation={activeConsultation}
                  saveAsTemplate={saveAsTemplate}
                  setSaveAsTemplate={setSaveAsTemplate}
                  onFinish={() => setTab("add-note")}
                />
              </div>
            </div>
          </div>
          <div className={s.secondRowWrapper}>
            <div className={s.firstColumnWrapper}>
              <div className={s.consultationWrapper}>
                <Toaster position="top-center" reverseOrder={false} />
                <div className={s.topWrapper}>
                  <h3 className={s.title}>Consultation</h3>
                  <ToggleButtonGroup
                    value={tab}
                    exclusive
                    onChange={(e) => setTab(e.target.value)}
                    aria-label="consultation tabs"
                    className={s.toggleBtnGroup}
                    disabled={!activeConsultation}
                  >
                    <ToggleButton
                      value="add-note"
                      aria-label="add note"
                      className={s.toggleBtn}
                      disableRipple
                    >
                      Add Note
                    </ToggleButton>
                    <ToggleButton
                      value="add-prescription"
                      aria-label="add prescription"
                      className={s.toggleBtn}
                      disableRipple
                    >
                      Add Prescription
                    </ToggleButton>
                    <ToggleButton
                      value="add-referral"
                      aria-label="add referral"
                      className={s.toggleBtn}
                      disableRipple
                    >
                      Add Referral
                    </ToggleButton>
                    <ToggleButton
                      value="add-letter"
                      aria-label="add letter"
                      className={s.toggleBtn}
                      disableRipple
                    >
                      Add Letter
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                {tab === "add-note" && (
                  <ConsultationEdit
                    consultation={activeConsultation}
                    isDisabled={!activeConsultation}
                    staffInfo={staffInfo}
                    saveAsTemplate={saveAsTemplate}
                    setSaveAsTemplate={setSaveAsTemplate}
                  />
                )}
                {tab === "add-referral" && (
                  <AddReferralForm
                    patient={patient}
                    consultationId={activeConsultation?.id}
                    initialData={activeConsultationReferral?.at(0) || undefined}
                    autoSave={true}
                  />
                )}
                {tab === "add-prescription" && (
                  <AddPrescriptionForm
                    autoSave={true}
                    consultationId={activeConsultation?.id}
                    initialData={{
                      id: activeConsultationPrescription?.id,
                      instructions: activeConsultationPrescription?.note || "",
                      filePath: activeConsultationPrescription?.filePath,
                      medicines:
                        activeConsultationPrescription?.medications?.map(
                          (medication) => ({
                            id: medication.id,
                            dosage: medication.dosage || "",
                            form: medication.form || "",
                            startDate: moment(medication.startDate).format(
                              "DD-MM-YYYY"
                            ),
                            finishDate: moment(medication.finishDate).format(
                              "DD-MM-YYYY"
                            ),
                            duration: medication.duration || "",
                            durationFormat: medication.durationFormat || "",
                            usageType: medication.acuteLongTerm || "",
                            repeat: medication.repeat || "",
                            tradeName: medication.tradeName || "",
                            manufacturer: medication.manufacturer || "",
                            packSize: medication.pack || "",
                            medicineId: medication.medicationId || "",
                            route: medication.route || "",
                            frequency: medication.frequency || "",
                            isManual: !medication.medicationId,
                            quantity: medication.quantity || "",
                          })
                        ),
                    }}
                  />
                )}
                {tab === "add-letter" && (
                  <AddLetterForm
                    patient={patient}
                    consultationId={activeConsultation?.id}
                    initialData={activeConsultationLetter?.at(0) || undefined}
                    autoSave={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientConsult;
