import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormField } from "../../../../../../components/form/FormField/FormField";
import Modal from "../../../../../../components/helpers/Modal/Modal";
import useSmsTemplates from "../../../../../../hooks/useSmsTemplates";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { createAxiosInstance } from "../../../../../../api/axiosConfig";
import {
  SMSMESSAGE_APPOINTMENTS,
  SMSMESSAGE_PATIENTS,
} from "../../../../../../api/Service";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const extractIds = (selectedRows) => {
  return Object.keys(selectedRows).map((key) => key.split("-")[0]);
};

const formSchema = z.object({
  message: z
    .string()
    .min(1, { message: "Please provide content for message." }),
  smsTemplate: z
    .string()
    .min(1, { message: "Please select message template." }),
  selectedIds: z.array(z.string()),
});

const SendSMSModal = ({ onClose, selectedRows, reportType }) => {
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: "",
      smsTemplate: "",
      selectedIds: [...new Set(extractIds(selectedRows))],
    },
  });

  const { data: smsTemplates } = useSmsTemplates();

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const handleFormSubmit = async (formData) => {
    const reportTypeMap = {
      patients: "patientIds",
      appointments: "appointmentIds",
      immunisations: "patientIds",
      accounting: "patientIds",
    };

    const key = reportTypeMap[reportType];

    if (!key) return;

    const obj = {
      tenant: tenantVal,
      message: formData.message,
      [key]: formData.selectedIds,
    };

    const axiosInstance = createAxiosInstance();
    const response = await axiosInstance.post(
      `${tenantVal}${
        reportType === "appointments"
          ? SMSMESSAGE_APPOINTMENTS
          : SMSMESSAGE_PATIENTS
      }`,
      obj
    );

    if (response.status === 200) toast.success("Sms sent successfully.");
    else toast.error("Failed to send sms.");

    onClose();
  };

  return (
    <Modal overflowY="auto" onClose={onClose} title="Send sms" hideCancel>
      <FormProvider {...form}>
        <form
          className="d-flex flex-column gap-3"
          onSubmit={form.handleSubmit(handleFormSubmit)}
        >
          <FormField name="smsTemplate" label="SMS Template" select required>
            <label className="requiredValidator mb-1">SMS Templates</label>
            <select
              className="form-control p15"
              name="message"
              {...form.register("smsTemplate", {
                onChange: (e) => {
                  form.setValue("message", e.target.value);
                  form.trigger("message");
                },
              })}
            >
              {smsTemplates?.map((option) => {
                if (!option.text)
                  return (
                    <option key={option.value} label={option.label} value={""}>
                      {option.label}
                    </option>
                  );
                return (
                  <option
                    key={option.value}
                    label={option.label}
                    value={option.text}
                  >
                    {option.label}
                  </option>
                );
              })}
            </select>
          </FormField>

          <FormField name="message" label="Message">
            <label className="requiredValidator mb-1">Message</label>
            <textarea
              className="form-control textareaform-control"
              rows="4"
              {...form.register("message")}
            />
          </FormField>

          <button className="saveButton savwidth ms-auto" type="submit">
            Save
          </button>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default SendSMSModal;
