import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "../../../api/axios";

import { LOST_PASS_URL } from "../../../api/Service";
import { Link, useNavigate } from "react-router-dom";
import badicon from "../../../assets/images/badicon.svg";
import s from "./LostPassword.module.css";
import AuthLayout from "./AuthLayout/AuthLayout";
import { OutlinedInput } from "@mui/material";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
});

const ForgotPassword = () => {
  const [errMsg, setErrMsg] = useState("");

  const [status, setStatus] = useState(undefined);

  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios.post(LOST_PASS_URL, values, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (response.status === 200) {
        navigate("/success_msg");
      }
    } catch (error) {
      setStatus({ type: "error", error });
      if (!error?.response) {
        setErrMsg("No Server Response");
      } else if (error.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
    }
    setSubmitting(false);
  };

  return (
    <AuthLayout>
      <div className={s.container}>
        <div className={s.textContainer}>
          <h1 className={s.title}>Reset password</h1>

          <p>Enter your registered email address</p>
        </div>

        <Formik
          initialValues={{ email: "" }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              {status?.type === "error" && (
                <div className="badiconbox">
                  <div className="badiconimg">
                    <img src={badicon} alt="" />
                  </div>
                  <h6>{errMsg}</h6>
                </div>
              )}

              <div className={s.inputWrapper}>
                <label className={s.label}>Email</label>
                <Field
                  as={OutlinedInput}
                  sx={{
                    background: "white",
                    borderRadius: "12px",
                    height: "48px",
                    "&.Mui-focused": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#006546",
                      },
                    },
                  }}
                  name="email"
                  type="email"
                  id="floatingInput"
                />
                {errors.email && touched.email && (
                  <div className="errorstext">{errors.email}</div>
                )}
              </div>

              <button
                className={s.submitBtn}
                type="submit"
                disabled={isSubmitting}
              >
                Send
              </button>

              <Link to="/login" className={s.backBtn}>
                Back to login
              </Link>
            </Form>
          )}
        </Formik>
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;
