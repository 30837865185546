import { useMainService } from "../../../../../hooks/useMainService";
import { useUpdateFamilyPaymentStatus } from "../../../../../hooks/useUpdateFamilyPaymentStatus";
import styles from "./FamilyPaymentsTable.module.css";

export const FamilyPaymentsTableItem = ({ item }) => {
  const { data: mainService } = useMainService(
    item?.consultation?.mainPracticeServiceId
  );

  const { mutate: changeStatus } = useUpdateFamilyPaymentStatus();

  return (
    <li className={styles.tableItem}>
      <p aria-describedby="report-table-due-date">{item?.dueDate || "-"}</p>
      <p aria-describedby="report-table-service-name">
        {mainService?.name || "-"}
      </p>
      <p aria-describedby="report-table-amount-owed">€{item?.amountDue ?? 0}</p>
      {item.paymentStatus === "PAID" && (
        <button
          className={styles.downloadLink}
          onClick={() => changeStatus({ id: item.id, status: "PAID" })}
        >
          Receipt
        </button>
      )}
      {item.paymentStatus === "DUE" && (
        <button
          className={styles.downloadLink}
          onClick={() => changeStatus({ id: item.id, status: "DUE" })}
        >
          Pay
        </button>
      )}
    </li>
  );
};
