import React, { useState } from "react";
import { Dialog, DialogTitle, IconButton, Pagination } from "@mui/material";
import { Document, Page } from "react-pdf";
import toast from "react-hot-toast";
import CustomButton from "../../helpers/CustomButton/CustomButton";
import PrintIcon from "../../../assets/images/printer-green.svg";
import s from "./PDFDisplayModal.module.css";
import Close from "../../../assets/images/close-black.svg";
import DownloadIcon from "../../../assets/images/download-green.svg";

import { pdfjs } from "react-pdf";
import usePatientLabel from "../../../hooks/usePatientLabel";
import { Loader } from "../../Loader/Loader";
import printJS from "print-js";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PDFDisplayModal = ({ open, onClose }) => {
  const { data: pdfData } = usePatientLabel();

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const printPdf = () => {
    try {
      const url = URL.createObjectURL(pdfData);
      printJS({
        printable: url,
        type: "pdf",
        showModal: false,
      });
    } catch (error) {
      toast.error("Error printing PDF: " + error.message);
    }
  };

  const downloadPDF = () => {
    try {
      const url = window.URL.createObjectURL(pdfData);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "patient-label.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error("Error downloading PDF: " + error.message);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "12px" } }}
    >
      <DialogTitle>Label preview</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <img src={Close} alt="close icon" />
      </IconButton>
      <div className={s.modalContent}>
        <div className={s.pdfContainer}>
          {pdfData ? (
            <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                pageNumber={pageNumber}
                height={200}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Document>
          ) : (
            <Loader />
          )}
        </div>

        {numPages > 1 && (
          <Pagination
            count={numPages}
            page={pageNumber}
            onChange={(event, value) => setPageNumber(value)}
            color="primary"
          />
        )}

        <div className={s.buttonContainer}>
          <CustomButton
            buttonClassName={s.button}
            title={"Download PDF"}
            icon={DownloadIcon}
            buttonHandle={downloadPDF}
          />

          <CustomButton
            buttonClassName={s.button}
            title={"Print PDF"}
            icon={PrintIcon}
            buttonHandle={printPdf}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default PDFDisplayModal;
