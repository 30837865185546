import { useState } from "react";

import style from "./AddPrescriptionModal.module.css";
import Modal from "../helpers/Modal/Modal";
import { AddPrescriptionForm } from "../AddPrescriptionForm/AddPrescriptionForm";

export const AddPrescriptionModal = ({
  isDisabled,
  buttonClassName,
  buttonIcon,
  consultationId,
  initialData,
  initialMedications,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <button
        className={`${style.openModalButton} ${buttonClassName}`}
        type="button"
        disabled={isDisabled}
        onClick={() => setIsModalOpen(true)}
      >
        {initialData ? "Edit prescription" : "Add prescription"} {buttonIcon}
      </button>
      {isModalOpen && (
        <Modal
          title={initialData ? "Edit prescription" : "Add prescription"}
          position="center"
          onClose={() => setIsModalOpen(false)}
          hideCancel={true}
          width="1100px"
          maxHeight="90vh"
        >
          <AddPrescriptionForm
            consultationId={consultationId}
            onSuccess={() => setIsModalOpen(false)}
            onCancel={() => setIsModalOpen(false)}
            initialData={initialData}
            initialMedications={initialMedications}
          />
        </Modal>
      )}
    </>
  );
};
