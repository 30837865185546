import React, { useState } from "react";
import styles from "../PatientCard/PatientCard.module.css";
import moment from "moment";
import { PatientOverviewModal } from "../../../../components/PatientDetailCardWithOverview/components/PatientOverviewModal/PatientOverviewModal.jsx";
import { usePatientData } from "../../../../hooks/usePatientData.js";
import CustomButton from "../../../../components/helpers/CustomButton/CustomButton.jsx";
import PrinterIcon from "../../../../assets/images/printer-green.svg";
import PDFDisplayModal from "../../../../components/Modal/PDFDisplayModal/PDFDisplayModal.jsx";

const calculateAge = (birthDate) => {
  const today = new Date();
  const birth = new Date(birthDate);
  let age = today.getFullYear() - birth.getFullYear();
  const monthDifference = today.getMonth() - birth.getMonth();
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birth.getDate())
  ) {
    age--;
  }
  return age;
};

const PatientDetailCard = () => {
  const { data: patient, isLoading } = usePatientData();
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);

  if (isLoading) {
    return (
      <div
        className={`${styles.patientDetailCard} bg patient-detail-summary-wrap `}
      >
        Loading...
      </div>
    );
  }

  const age = calculateAge(patient.dateOfBirth);

  const { city, addressLine1, addressLine2, postCode } = patient
    ? patient.address
    : {};

  return (
    <div
      className={`${styles.patientDetailCard} bg patient-detail-summary-wrap `}
    >
      <div className={styles.patientHeaderWrap}>
        <div className="d-flex align-items-center">
          <div className={styles.patientHeaderContentWrap}>
            <div className={styles.patientName}>
              <p
                title={patient.firstName}
                className={`text-ellipsis ${styles.patientNameSurname}`}
                style={{ fontSize: "23px" }}
              >
                {patient.firstName} {patient.surname} ({age})
              </p>
            </div>
            <p>
              DOB : {moment(patient.dateOfBirth).format("dd-MM-yyyy")}
              <span style={{ color: "#ef0064", paddingLeft: "20px" }}>
                {" "}
                {patient.died ? "DECEASED" : ""}
              </span>
            </p>
            <CustomButton
              buttonClassName="p-2 ps-0 border-0"
              title="Print"
              icon={PrinterIcon}
              buttonHandle={() => setIsPdfModalOpen(true)}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.patientBottomWrap} d-flex flex-wrap`}>
        <div className={styles.patientLeftDescription}>
          <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} d-inline-block`}>
              Phone no:
            </span>
            <h6 className={`${styles.h6} ${styles.textStratos} mb-0`}>
              {patient.mobilePhone ? patient.mobilePhone : patient.homePhone}
            </h6>
          </div>

          <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} fw-500 d-inline-block`}>
              Address:
            </span>
            <h6 className={`${styles.h6} ${styles.textStratos} fw-500 mb-0 `}>
              {addressLine2 ? addressLine2 : addressLine1},{" "}
              {city && `${city} ${postCode}`}
            </h6>
          </div>

          <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} fw-500 d-inline-block`}>
              Public:
            </span>
            <h6
              className={`${styles.h6} ${styles.textStratos} fw-500 mb-0 ${styles.textEllipsis}`}
            >
              Medical card no. {patient.medicalCardNumber}
            </h6>
          </div>
        </div>
      </div>
      <PatientOverviewModal />

      <PDFDisplayModal
        open={isPdfModalOpen}
        onClose={() => setIsPdfModalOpen(false)}
      />
    </div>
  );
};

export default PatientDetailCard;
