import React from "react";

import AuthLayout from "./AuthLayout/AuthLayout";
import { Link } from "react-router-dom";
import s from "./Success_msg.module.css";
import Image from "../../../assets/images/auth/password-reset.svg";

const Success_msg = () => {
  return (
    <AuthLayout>
      <div className={s.container}>
        <h1 className={s.title}>Password reset link sent</h1>
        <p className={s.secondaryText}>
          Please check your email for instructions to reset your password
        </p>

        <img src={Image} alt="Envelope" />

        <Link to="/login" className={s.button}>
          Back to login
        </Link>
      </div>
    </AuthLayout>
  );
};

export default Success_msg;
