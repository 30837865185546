import { useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useAdminView = (path, shouldAlert = true) => {
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.auth.user?.role);

  useEffect(() => {
    if (userRole !== "ADMIN") {
      navigate(path || "/allied_home");

      if (shouldAlert) {
        toast.error("You are not authorized to access this page");
      }
    }
  }, [navigate, path, userRole, shouldAlert]);
};
