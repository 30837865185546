import { createAxiosInstance } from "../../../../api/axiosConfig.js";

const getPath = (reportType) => {
  switch (reportType) {
    case "immunisations":
      return "get/immunisations";
    case "prescriptions":
      return "get/prescriptions";
    case "investigations":
      return "get/invesgigations";
    case "letters":
      return "get/letters";
    case "accounting":
      return "get/accounting";
    case "appointments":
      return "get/appointments";
    case "medico-legal":
      return "get/medico-legal";
    case "audit-log":
      return "get/auditlog";
    default:
      return "get/patients";
  }
};

export const exportReports = async (tenantVal, reportType) => {
  const axiosInstance = createAxiosInstance();
  const url = `${tenantVal}/reports/${getPath(reportType)}/export`;

  try {
    const response = await axiosInstance.get(url, {
      responseType: "arraybuffer",
    });

    const fileName = `${reportType ?? "patients"}.xls`;

    const blob = new Blob([response.data]);

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error("Error exporting reports:", error);
  }
};
