import moment from "moment/moment";
import PatientDetailButton from "../../../../components/PatientDetailsButton/PatientDetailsButton";
import { Checkbox } from "@mui/material";

export const fetchColumns = (reportType) => {
  switch (reportType) {
    case "immunisations":
      return getImmunisationCols();
    case "prescriptions":
      return getMedicationCols();
    case "investigations":
      return getReferralCols();
    case "letters":
      return getLetterCols();
    case "accounting":
      return getPaymentCols();
    case "appointments":
      return getAppointmentCols();
    case "medico-legal":
      return getPatientCols();
    case "audit-log":
      return getAuditCols();
    default:
      return getPatientCols();
  }
};

const getAuditCols = () => {
  return [
    {
      accessorKey: "accessDateTime",
      header: "Date accessed",
      cell: (info) => {
        const date = info.getValue();

        if (!date) return "N/A";

        return moment(date).format("DD/MM/YYYY");
      },
      size: 135,
    },
    {
      cell: ({ row }) => {
        const fullName = `${row.original.firstName || ""} ${
          row.original.lastName || ""
        }`;
        const patientId = row.original.patientId;
        return (
          <PatientDetailButton
            patientId={patientId}
            patientFullName={fullName}
          />
        );
      },
      header: "Patient name",
      size: 150,
    },
    {
      accessorKey: "dateOfBirth",
      header: "Date of Birth",
      cell: (info) => moment(info.getValue()).format("DD/MM/YYYY"),
      size: 120,
    },
    {
      accessorKey: "gender",
      header: "Gender",
      size: 70,
      cell: (info) =>
        info
          .getValue()
          ?.toLowerCase()
          .replace(/^\w/, (c) => c.toUpperCase()) || "N/A",
    },
    {
      accessorFn: (row) => {
        if (!row.staffFirstName && !row.staffLastName) return "N/A";

        return `${row.staffFirstName || ""} ${row.staffLastName || ""}`;
      },
      header: "Accessed by",
      size: 150,
    },
    {
      accessorKey: "accessReason",
      header: "Access reason",
      cell: (info) => info.getValue() || "-",
      size: 90,
    },
    {
      accessorKey: "dailyInteractions",
      header: "Daily access count",
      cell: (info) => info.getValue() || "-",
      size: 90,
    },
  ];
};

const getPatientCols = () => {
  return [
    {
      id: "select-col",
      header: ({ table }) => (
        <Checkbox
          sx={{
            "&.Mui-checked": {
              color: "var(--green-900)",
            },
            "&.MuiCheckbox-indeterminate": {
              color: "var(--green-900)",
            },
          }}
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllPageRowsSelectedHandler()}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          sx={{
            "&.Mui-checked": {
              color: "var(--green-900)",
            },
          }}
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          onChange={row.getToggleSelectedHandler()}
        />
      ),
      size: 80,
    },
    {
      accessorKey: "dateOfConsultation",
      header: "Consultation Date",
      cell: (info) => {
        const date = info.getValue();

        if (!date) return "N/A";

        return moment(date).format("DD/MM/YYYY");
      },
      size: 135,
    },
    {
      cell: ({ row }) => {
        const fullName = `${row.original.firstName || ""} ${
          row.original.lastName || ""
        }`;
        const patientId = row.original.patientId;
        return (
          <PatientDetailButton
            patientId={patientId}
            patientFullName={fullName}
          />
        );
      },
      header: "Name",
      size: 150,
    },
    {
      accessorKey: "dateOfBirth",
      header: "Date of Birth",
      cell: (info) => moment(info.getValue()).format("DD/MM/YYYY"),
      size: 120,
    },
    {
      accessorKey: "gender",
      header: "Gender",
      size: 60,
      cell: (info) =>
        info
          .getValue()
          ?.toLowerCase()
          .replace(/^\w/, (c) => c.toUpperCase()) || "N/A",
    },
    {
      accessorFn: (row) => {
        if (!row.doctorFirstName && !row.doctorSecondName) return "N/A";

        return `${row.doctorFirstName || ""} ${row.doctorSecondName || ""}`;
      },
      header: "Doctor Name",
      size: 150,
    },
    {
      accessorKey: "consultationName",
      header: "Consultation",
      cell: (info) => info.getValue() || "-",
      size: 120,
    },
    {
      accessorKey: "prescription",
      header: "Prescription",
      cell: (info) => info.getValue() || "-",
      size: 60,
    },
    {
      accessorKey: "investigation",
      header: "Investigation",
      cell: (info) => info.getValue() || "-",
      size: 60,
    },
    {
      accessorKey: "referral",
      header: "Referral",
      cell: (info) => info.getValue() || "-",
      size: 60,
    },
  ];
};

const getMedicationCols = () => {
  return [
    {
      accessorKey: "prescriptionDate",
      header: "Prescribed Date",
      cell: (info) => {
        const date = info.getValue();

        if (!date) return "N/A";

        return moment(date).format("DD/MM/YYYY");
      },
      size: 60,
    },
    {
      cell: ({ row }) => {
        const fullName = `${row.original.firstName || ""} ${
          row.original.lastName || ""
        }`;
        const patientId = row.original.patientId;
        return (
          <PatientDetailButton
            patientId={patientId}
            patientFullName={fullName}
          />
        );
      },
      header: "Name",
      size: 120,
    },
    {
      accessorKey: "dateOfBirth",
      header: "Date of Birth",
      cell: (info) => moment(info.getValue()).format("DD/MM/YYYY"),
      size: 50,
    },
    {
      accessorKey: "gender",
      header: "Gender",
      size: 50,
      cell: (info) =>
        info
          .getValue()
          ?.toLowerCase()
          .replace(/^\w/, (c) => c.toUpperCase()) || "N/A",
    },
    {
      accessorKey: "tradeName",
      header: "Drug name",
      cell: (info) => info.getValue() || "-",
      size: 120,
    },
    {
      accessorKey: "manufacturer",
      header: "Manufacturer",
      cell: (info) => info.getValue() || "-",
      size: 120,
    },
    {
      accessorKey: "form",
      header: "Form",
      cell: (info) => info.getValue() || "-",
      size: 60,
    },
  ];
};

const getReferralCols = () => {
  return [
    {
      accessorKey: "dateSent",
      header: "Date Sent",
      cell: (info) => {
        const date = info.getValue();

        if (!date) return "N/A";

        return moment(date).format("DD/MM/YYYY");
      },
      size: 60,
    },
    {
      cell: ({ row }) => {
        const fullName = `${row.original.firstName || ""} ${
          row.original.lastName || ""
        }`;
        const patientId = row.original.patientId;
        return (
          <PatientDetailButton
            patientId={patientId}
            patientFullName={fullName}
          />
        );
      },
      header: "Name",
      size: 120,
    },
    {
      accessorKey: "dateOfBirth",
      header: "Date of Birth",
      cell: (info) => moment(info.getValue()).format("DD/MM/YYYY"),
      size: 50,
    },
    {
      accessorKey: "gender",
      header: "Gender",
      size: 50,
      cell: (info) =>
        info
          .getValue()
          ?.toLowerCase()
          .replace(/^\w/, (c) => c.toUpperCase()) || "N/A",
    },
    {
      accessorFn: (row) => {
        if (!row.doctorFirstName && !row.doctorSecondName) return "N/A";

        return `${row.doctorFirstName || ""} ${row.doctorSecondName || ""}`;
      },
      header: "Doctor Name",
      size: 90,
    },
    {
      accessorKey: "referralType",
      header: "Type",
      cell: (info) => info.getValue() || "-",
      size: 120,
    },
  ];
};

const getAppointmentCols = () => {
  return [
    {
      id: "select-col",
      header: ({ table }) => (
        <Checkbox
          sx={{
            "&.Mui-checked": {
              color: "var(--green-900)",
            },
            "&.MuiCheckbox-indeterminate": {
              color: "var(--green-900)",
            },
          }}
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllPageRowsSelectedHandler()}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          sx={{
            "&.Mui-checked": {
              color: "var(--green-900)",
            },
          }}
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          onChange={row.getToggleSelectedHandler()}
        />
      ),
      size: 80,
    },
    {
      accessorKey: "appointmentDate",
      header: "Date Sent",
      cell: (info) => {
        const date = info.getValue();

        if (!date) return "N/A";

        return moment(date).format("DD/MM/YYYY");
      },
      size: 60,
    },
    {
      accessorKey: "serviceName",
      header: "Service",
      cell: (info) => info.getValue() || "-",
      size: 120,
    },
    {
      cell: ({ row }) => {
        const fullName = `${row.original.firstName || ""} ${
          row.original.lastName || ""
        }`;
        const patientId = row.original.patientId;
        return (
          <PatientDetailButton
            patientId={patientId}
            patientFullName={fullName}
          />
        );
      },
      header: "Name",
      size: 120,
    },
    {
      accessorKey: "dateOfBirth",
      header: "Date of Birth",
      cell: (info) => moment(info.getValue()).format("DD/MM/YYYY"),
      size: 50,
    },
    {
      accessorKey: "gender",
      header: "Gender",
      size: 50,
      cell: (info) =>
        info
          .getValue()
          ?.toLowerCase()
          .replace(/^\w/, (c) => c.toUpperCase()) || "N/A",
    },
    {
      accessorFn: (row) => {
        if (!row.doctorFirstName && !row.doctorSecondName) return "N/A";

        return `${row.doctorFirstName || ""} ${row.doctorSecondName || ""}`;
      },
      header: "Doctor Name",
      size: 90,
    },
    {
      accessorKey: "appointmentStatus",
      header: "Appointment Status",
      cell: (info) => info.getValue() || "-",
      size: 100,
    },
  ];
};

const getPaymentCols = () => {
  return [
    {
      id: "select-col",
      header: ({ table }) => (
        <Checkbox
          sx={{
            "&.Mui-checked": {
              color: "var(--green-900)",
            },
            "&.MuiCheckbox-indeterminate": {
              color: "var(--green-900)",
            },
          }}
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllPageRowsSelectedHandler()}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          sx={{
            "&.Mui-checked": {
              color: "var(--green-900)",
            },
          }}
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          onChange={row.getToggleSelectedHandler()}
        />
      ),
      size: 80,
    },
    {
      accessorKey: "dueDate",
      header: "Created Date",
      cell: (info) => {
        const date = info.getValue();

        if (!date) return "N/A";

        return moment(date).format("DD/MM/YYYY");
      },
      size: 60,
    },
    {
      accessorKey: "serviceName",
      header: "Service",
      cell: (info) => info.getValue() || "-",
      size: 120,
    },
    {
      cell: ({ row }) => {
        const fullName = `${row.original.firstName || ""} ${
          row.original.lastName || ""
        }`;
        const patientId = row.original.patientId;
        return (
          <PatientDetailButton
            patientId={patientId}
            patientFullName={fullName}
          />
        );
      },
      header: "Name",
      size: 120,
    },
    {
      accessorKey: "dateOfBirth",
      header: "Date of Birth",
      cell: (info) => moment(info.getValue()).format("DD/MM/YYYY"),
      size: 50,
    },
    {
      accessorKey: "gender",
      header: "Gender",
      size: 50,
      cell: (info) =>
        info
          .getValue()
          ?.toLowerCase()
          .replace(/^\w/, (c) => c.toUpperCase()) || "N/A",
    },
    {
      accessorKey: "paymentStatus",
      header: "Payment Status",
      cell: (info) => info.getValue() || "-",
      size: 100,
    },
    {
      accessorKey: "amountDue",
      header: "Amount",
      cell: (info) => info.getValue() || "-",
      size: 100,
    },
  ];
};

const getLetterCols = () => {
  return [
    {
      accessorKey: "dateSent",
      header: "Date Sent",
      cell: (info) => {
        const date = info.getValue();

        if (!date) return "N/A";

        return moment(date).format("DD/MM/YYYY");
      },
      size: 60,
    },
    {
      cell: ({ row }) => {
        const fullName = `${row.original.firstName || ""} ${
          row.original.lastName || ""
        }`;
        const patientId = row.original.patientId;
        return (
          <PatientDetailButton
            patientId={patientId}
            patientFullName={fullName}
          />
        );
      },
      header: "Name",
      size: 120,
    },
    {
      accessorKey: "dateOfBirth",
      header: "Date of Birth",
      cell: (info) => moment(info.getValue()).format("DD/MM/YYYY"),
      size: 50,
    },
    {
      accessorKey: "gender",
      header: "Gender",
      size: 50,
      cell: (info) =>
        info
          .getValue()
          ?.toLowerCase()
          .replace(/^\w/, (c) => c.toUpperCase()) || "N/A",
    },
    {
      accessorFn: (row) => {
        if (!row.doctorFirstName && !row.doctorSecondName) return "N/A";

        return `${row.doctorFirstName || ""} ${row.doctorSecondName || ""}`;
      },
      header: "Doctor Name",
      size: 90,
    },
    {
      accessorKey: "letterType",
      header: "Letter Type",
      cell: (info) => info.getValue() || "-",
      size: 120,
    },
  ];
};

const getImmunisationCols = () => {
  return [
    {
      id: "select-col",
      header: ({ table }) => (
        <Checkbox
          sx={{
            "&.Mui-checked": {
              color: "var(--green-900)",
            },
            "&.MuiCheckbox-indeterminate": {
              color: "var(--green-900)",
            },
          }}
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllPageRowsSelectedHandler()}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          sx={{
            "&.Mui-checked": {
              color: "var(--green-900)",
            },
          }}
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          onChange={row.getToggleSelectedHandler()}
        />
      ),
      size: 80,
    },
    {
      accessorKey: "dateAdministered",
      header: "Administered Date",
      cell: (info) => {
        const date = info.getValue();

        if (!date) return "N/A";

        return moment(date).format("DD/MM/YYYY");
      },
      size: 135,
    },
    {
      cell: ({ row }) => {
        const fullName = `${row.original.firstName || ""} ${
          row.original.lastName || ""
        }`;
        const patientId = row.original.patientId;
        return (
          <PatientDetailButton
            patientId={patientId}
            patientFullName={fullName}
          />
        );
      },
      header: "Name",
      size: 150,
    },
    {
      accessorKey: "dateOfBirth",
      header: "Date of Birth",
      cell: (info) => moment(info.getValue()).format("DD/MM/YYYY"),
      size: 120,
    },
    {
      accessorKey: "gender",
      header: "Gender",
      size: 70,
      cell: (info) =>
        info
          .getValue()
          ?.toLowerCase()
          .replace(/^\w/, (c) => c.toUpperCase()) || "N/A",
    },
    {
      accessorKey: "vaccineName",
      header: "Vaccine Name",
      cell: (info) => info.getValue() || "-",
      size: 90,
    },
    {
      accessorKey: "batchNo",
      header: "Batch No",
      cell: (info) => info.getValue() || "-",
      size: 90,
    },
    {
      accessorKey: "reaction",
      header: "Reaction",
      cell: (info) => info.getValue() || "-",
      size: 90,
    },
  ];
};
