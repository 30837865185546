import { SMS_TEMPLATE_CONTROLLER } from "../api/Service";
import { useSelector } from "react-redux";
import { createAxiosInstance } from "../api/axiosConfig";
import { useQuery } from "@tanstack/react-query";

const useSmsTemplates = () => {
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["smsTemplates", tenantVal],
    queryFn: async () => {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${SMS_TEMPLATE_CONTROLLER}`
      );

      const templates = [{ label: "SMS Templates*", value: "" }];

      if (response && response.data) {
        response.data.forEach((element) => {
          templates.push({
            label: element.name,
            value: element.id,
            text: element.template,
          });
        });
      }

      return templates;
    },
    onError: (error) => {
      console.log(error);
    },
    enabled: !!tenantVal,
  });
};

export default useSmsTemplates;
