import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "../../../api/axios";
import { RESET_PASS_URL } from "../../../api/Service";
import { useNavigate } from "react-router-dom";
import AuthLayout from "./AuthLayout/AuthLayout";
import s from "./ResetPasswordEmail.module.css";
import PasswordInput from "./components/PasswordInput";

// Validation for reset password//
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .max(255)
    .min(6, "Password must be at least 6 characters long")
    .required("Password is required"),

  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

// Form fieldsinitial value//
const initialValues = {
  password: "",
  passwordConfirmation: "",
};

const ResetPasswordEmail = () => {
  // Get url param//
  const params = new URLSearchParams(window.location.search);
  const userCode = params.get("user_code");
  const resetId = params.get("reset_id");
  // Get url param//

  // Navigation//
  const navigate = useNavigate();

  // Api header//
  const headers = {
    Accept: "*",
    "Content-Type": "application/json",
    authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
  };

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post(
        RESET_PASS_URL,
        {
          userCode: userCode,
          resetId: resetId,
          password: values.password,
          password2: values.passwordConfirmation,
        },
        { headers: headers }
      );

      if (response.status === 200) {
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthLayout>
      <div className={s.container}>
        <div className={s.textContainer}>
          <h1 className={s.title}>Create New Password</h1>
          <p className={s.secondaryText}>Please create your new password</p>
        </div>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <div className={s.inputsContainer}>
                <div className={s.inputWrapper}>
                  <label className={s.label}>New password</label>
                  <PasswordInput id="password" name="password" />
                  {errors.password && touched.password && (
                    <div className="errorstext">{errors.password}</div>
                  )}
                </div>

                <div className={s.inputWrapper}>
                  <label className={s.label}>Confirm new password</label>
                  <PasswordInput
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                  />
                  {errors.passwordConfirmation &&
                    touched.passwordConfirmation && (
                      <div className="errorstext">
                        {errors.passwordConfirmation}
                      </div>
                    )}
                </div>
              </div>

              <button
                className={s.submitBtn}
                type="submit"
                disabled={isSubmitting}
              >
                Save
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </AuthLayout>
  );
};

export default ResetPasswordEmail;
