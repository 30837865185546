import React, { useEffect, useState } from "react";
import NavigationBar from "../../PatientDetailGp/components/NavigationBar/NavigationBar.jsx";
import { createAxiosInstance } from "../../../../api/axiosConfig.js";
import {
  PATIENT_CONTROLER_URL_ID,
  MESSAGESENT_PATIENT_CONTROLLER_URL,
  MESSAGESENT_PATIENT_SEARCHCONTROLLER_URL,
  TASK_CONTROLER_CLOSE,
} from "../../../../api/Service.js";
import { useSelector } from "react-redux";
import PatientCard from "../PatientCard/PatientCard.jsx";
import EmptyState from "./EmptyState.jsx";
import CommsMenu from "../../Communication/CommunicationMenu/CommunicationMenu.jsx";

import s from "./PatientCommunication.module.css";
import PatientSms from "./PatientSms.jsx";
import Modal from "../../../../components/helpers/Modal/Modal.jsx";
import SmsModal2 from "../../../../components/Modal/SmsModal2.jsx";
import { usePatientSmsHistory } from "../../../../hooks/usePatientSmsHistory.js";
import { usePatientData } from "../../../../hooks/usePatientData.js";
import { Loader } from "../../../../components/Loader/Loader.jsx";

const PatientCommunication = () => {
  const patientId = localStorage.getItem("kinesin-patientId");
  const { data: patient, isLoading: isLoadingPatient } = usePatientData();
  const {
    data: smsData,
    isLoading: isLoadingSms,
    refetch: refetchSms,
  } = usePatientSmsHistory(patientId);

  const isLoading = isLoadingPatient || isLoadingSms;
  const [menuItem, setMenuItem] = useState("SMS");

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const menuClick = (value) => {
    setMenuItem(value);
    if (value === "SMS") refetchSms();
  };

  let [isSmsModalOpen, setIsSmsModalOpen] = useState(false);

  const toggleSmsModal = () => {
    isSmsModalOpen = !isSmsModalOpen;
    setIsSmsModalOpen(isSmsModalOpen);
    refetchSms();
  };

  return (
    <>
      <NavigationBar />
      <div style={{ margin: "0 auto" }}>
        <div className={s.wrapper}>
          <div className={s.firstColumnWrapper}>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <PatientCard />
                <CommsMenu menuClick={menuClick}></CommsMenu>
              </>
            )}
          </div>
          <div className={s.secondColumnWrapper}>
            <div className={s.secondColumnSubWrapper}>
              {isLoadingPatient ? (
                <Loader />
              ) : smsData && smsData.length > 0 ? (
                <PatientSms
                  handleClick={toggleSmsModal}
                  smsList={smsData}
                  patient={patient}
                />
              ) : (
                <EmptyState toggleSmsModal={toggleSmsModal} />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isSmsModalOpen && (
        <Modal onClose={toggleSmsModal} title={"Send sms"} hideCancel={true}>
          {/* Modal */}
          <SmsModal2
            tenantVal={tenantVal}
            patient={patient}
            onClose={toggleSmsModal}
          />
          {/* Modal */}
        </Modal>
      )}
      {/* Modal */}
    </>
  );
};

export default PatientCommunication;
