import React from "react";

import {
  GET_PATIENT_BASELINE_BY_ID,
  POST_PATIENT_BASELINE,
} from "../../../../../api/Service.js";
import CardWithFormModal from "../../../../../components/helpers/CardWithFormModal/CardWithFormModal.jsx";
import Pencil from "../../../../../assets/images/edit-green-pencil.svg";

const BaselineDetails = () => {
  return (
    <>
      <CardWithFormModal
        title="Baseline Details"
        cardIcon={Pencil}
        minWidth={"295px"}
        apiEndpoints={{
          get: GET_PATIENT_BASELINE_BY_ID,
          post: POST_PATIENT_BASELINE,
        }}
        formFields={[
          {
            label: "Height (cm)",
            type: "number",
            name: "heightCm",
            placeholder: "enter height",
          },
          {
            label: "Weight (kg)",
            type: "number",
            name: "weightKg",
            placeholder: "enter weight",
          },

          {
            label: "Waist (cm)",
            type: "number",
            name: "waistCm",
            placeholder: "enter waist",
          },
          {
            label: "BP",
            type: "text",
            name: "bloodPressure",
            placeholder: "enter BP",
          },
        ]}
        localStorageKeys={{
          tenant: "kinesin-tenant",
          patientId: "kinesin-patientId",
        }}
      />
    </>
  );
};

export default BaselineDetails;
