import { createAxiosInstance } from "../../../../api/axiosConfig.js";

const getPath = (reportType) => {
  switch (reportType) {
    case "immunisations":
      return "get/immunisations";
    case "prescriptions":
      return "get/prescriptions";
    case "investigations":
      return "get/invesgigations";
    case "letters":
      return "get/letters";
    case "accounting":
      return "get/accounting";
    case "appointments":
      return "get/appointments";
    case "medico-legal":
      return "get/medico-legal";
    case "audit-log":
      return "get/auditlog";
    default:
      return "get/patients";
  }
};

export const fetchReports = async (
  tenantVal,
  reportType,
  queryParams,
  page,
  rows
) => {
  const axiosInstance = createAxiosInstance();

  const path = `${tenantVal}/reports/${getPath(reportType)}${
    rows === "All" ? "" : "/page"
  }`;

  const url = `${path}?page=${page}${
    rows === "All" ? "" : `&rows=${rows}`
  }${queryParams.replace("?", "&")}`;

  const response = await axiosInstance.get(url);
  return response.data;
};
