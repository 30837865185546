import React from "react";
import s from "./AuthLayout.module.css";
import Ellipse1 from "../../../../assets/images/auth/ellipse-1.svg";
import Ellipse3 from "../../../../assets/images/auth/ellipse-3.svg";
import dots from "../../../../assets/images/auth/dots.svg";
import Image from "../../../../assets/images/auth/auth-bg.png";
import KinesinLogo from "../../../../assets/images/auth/kinesin-logo.svg";

const AuthLayout = ({ children }) => {
  return (
    <div className={s.container}>
      <div className={s.contentContainer}>
        <img className={s.logo} src={KinesinLogo} alt="Kinesin logo" />

        <img src={Ellipse1} alt="Green ellipse" className={s.ellipse1} />
        <img src={Ellipse3} alt="Green ellipse" className={s.ellipse3} />
        <img src={dots} alt="Green dots" className={s.dots} />

        <div className={s.content}>{children}</div>

        <div className={s.versionData}>
          Kinesin version: KINESIN_WEB_VERSION
        </div>
      </div>
      <div className={s.rightContainer}>
        <img src={Image} alt="Doctor" className={s.rightBgImage} />
      </div>
    </div>
  );
};

export default AuthLayout;
