import style from "./ConsultationBox.module.css";
import { FileText, Trash } from "lucide-react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { Image } from "react-bootstrap";

import DocIcon from "../../../../assets/images/doc-icon.svg";
import CustomButton from "../../../../components/helpers/CustomButton/CustomButton.jsx";
import { usePracticeServices } from "../../../../hooks/usePracticeServices.js";
import { useConsultationServices } from "../../../../hooks/useConsultationServices.js";
import { useAddConsultationService } from "../../../../hooks/useAddConsultationService.js";
import { useDeleteConsultationService } from "../../../../hooks/useDeleteConsultationService.js";
import { useMainService } from "../../../../hooks/useMainService.js";
import { useConfirmPayment } from "../../../../hooks/useConfirmPayment.js";
import { useChangeConsulationMainService } from "../../../../hooks/useChangeConsulationMainService.js";
import moment from "moment";

export const ConsultationBox = ({ staffName, consultation }) => {
  const [service, setService] = useState("Select Service");

  const hasActiveConsultation = !!consultation;
  const isPaid = consultation?.moneyOwed === false;

  const { data: mainService } = useMainService(
    consultation?.mainPracticeServiceId
  );

  const { data: services, isLoading: isServicesLoading } =
    usePracticeServices();
  const { data: consultationServices, isLoading: isPaymentsLoading } =
    useConsultationServices(consultation?.id);

  const addConsultationService = useAddConsultationService(consultation?.id);
  const deleteConsultationService = useDeleteConsultationService(
    consultation?.id
  );
  const confirmPaymentMutation = useConfirmPayment(consultation?.id);
  const changeMainService = useChangeConsulationMainService(consultation);

  const allConultationServices = [
    mainService ? { service: mainService, id: mainService?.id } : null,
    ...(consultationServices ?? []),
  ].filter(Boolean);

  return (
    <>
      <div className={style.consultationBoxWrapper}>
        <section className={style.consultationBox}>
          <div className={style.consultationBoxTop}>
            <div className={style.consultationBoxTopItem}>
              <h2 className={style.consultationTitle}>
                {consultation?.title || "Consultation"}
              </h2>
            </div>
            <div className={style.rightBox}>
              <p>
                <span>Doctor:</span> {staffName}
              </p>
              <p>
                <span>Consultation date:</span>{" "}
                {moment(consultation?.created, "DD-MM-YYYY HH:mm").format(
                  "DD-MM-YYYY HH:mm"
                )}
              </p>
            </div>
          </div>
          {hasActiveConsultation && (
            <div className={style.addServiceWrapper}>
              <select
                as="select"
                className="form-select form-control custom-select"
                value={service}
                onChange={(e) => setService(e.target.value)}
                disabled={isPaid}
              >
                <option value="Select Service">Select Service</option>
                {isServicesLoading ? (
                  <option>Loading services...</option>
                ) : (
                  (services ?? []).map((item, index) => (
                    <option value={item.id || index} key={item.id}>
                      {item.name}
                    </option>
                  ))
                )}
              </select>
              <button
                className={style.addServiceButton}
                onClick={() => {
                  if (service === "Select Service") {
                    toast.error("Please select a service");
                    return;
                  }
                  addConsultationService.mutate(service);
                }}
                disabled={isPaid}
              >
                Add additional service
              </button>
            </div>
          )}
        </section>
        <section className={style.paymentSection}>
          <h2 className={style.paymentHeader}>
            <span>Payment</span>
            <span>
              Total price: €
              {allConultationServices?.reduce(
                (acc, curr) => acc + curr?.service?.price,
                0
              ) ?? 0}
            </span>
          </h2>
          {isPaymentsLoading ? (
            <div className={style.paymentSectionLoading}>
              Loading payments...
            </div>
          ) : allConultationServices && allConultationServices.length > 0 ? (
            <>
              <ul className={style.paymentItems}>
                {allConultationServices.map((service, index) => (
                  <li key={service?.id || index} className={style.paymentItem}>
                    <div className={style.serviceInfo}>
                      <div className={style.iconWrapper}>
                        <FileText size={20} />
                      </div>
                      <span>{service?.service?.name}</span>
                    </div>
                    <div className={style.priceControls}>
                      <div className={style.priceInputWrapper}>
                        <input
                          type="text"
                          value={`€${service?.service?.price}`}
                          className="form-control"
                          readOnly
                          disabled={isPaid}
                        />
                      </div>
                      {allConultationServices?.length &&
                        allConultationServices?.length > 1 &&
                        !isPaid && (
                          <button
                            className={style.deleteButton}
                            onClick={async () => {
                              if (index === 0) {
                                await changeMainService.mutate(
                                  allConultationServices?.at(1)?.serviceId
                                );
                                await deleteConsultationService.mutate(
                                  allConultationServices?.at(1)?.id
                                );

                                return;
                              }
                              await deleteConsultationService.mutate(
                                service.id
                              );
                            }}
                            type="button"
                          >
                            <Trash size={20} />
                          </button>
                        )}
                    </div>
                  </li>
                ))}
              </ul>
              <div className={style.paymentConfirmButton}>
                <CustomButton
                  buttonHandle={() => confirmPaymentMutation.mutate()}
                  title={
                    isPaid
                      ? "Paid"
                      : confirmPaymentMutation.isPending
                      ? "Processing..."
                      : "Confirm payment"
                  }
                  buttonClassName={style.button}
                  icon={false}
                  disabled={confirmPaymentMutation.isPending || isPaid}
                />
              </div>
            </>
          ) : (
            <div className={style.paymentSectionNoPayment}>
              <Image src={DocIcon} alt="category" />
              <div>
                <h3>No Payment Details Yet</h3>
                <p>
                  Payment information will appear here once the consultation
                  starts.
                </p>
              </div>
            </div>
          )}
        </section>
      </div>
    </>
  );
};
