import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import CardWithTitle from "../CardWithTitle/CardWithTitle.jsx";
import Modal from "../Modal/Modal.jsx";
import ReuseForm from "../ReuseForm/ReuseForm.jsx";
import { getData, postData } from "../../API/index.js";
import toast, { Toaster } from "react-hot-toast";

import s from "./CardWithFormModal.module.css";
import PencilIcon from "../../../assets/images/edit-pencil-icon.svg";

const CardWithFormModal = ({
  title,
  staticFieldTitle,
  staticFieldData,
  apiEndpoints,
  formFields,
  tenantKey,
  patientIdKey,
  localStorageKeys,
  cardIcon = PencilIcon,
  marginBottom = "22px",
  showButton = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [formState, setFormState] = useState(
    formFields.reduce(
      (acc, field) => ({ ...acc, [field.name]: field.defaultValue || "" }),
      {}
    )
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem(localStorageKeys.tenant);

  const patientId = localStorage.getItem(localStorageKeys.patientId);

  const fetchData = async () => {
    try {
      const response = await getData(
        `${tenantVal}${apiEndpoints.get}${patientId}`
      );
      setData(response);
      updateFormState(response);
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
      console.log(error);
    }
  };

  const updateFormState = (data) => {
    const latestData = data.length > 0 ? data[data.length - 1] : {};
    setFormState(
      formFields.reduce(
        (acc, field) => ({
          ...acc,
          [field.name]: latestData[field.name] || field.defaultValue || "",
        }),
        {}
      )
    );
  };

  useEffect(() => {
    fetchData();
  }, [patientId]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const latestData = data.length > 0 ? data[data.length - 1] : null;
    const formData = {
      id: latestData ? latestData.id : null,
      patientId,
      ...formState,
    };
    try {
      const response = await postData(
        `${tenantVal}${apiEndpoints.post}`,
        formData
      );
      toast.success("Data saved successfully!");
      setIsModalOpen(false);
      fetchData();
    } catch (error) {
      toast.error(`Error saving data: ${error.message}`);
      console.error("There was a problem with the axios operation:", error);
    }
  };

  const latestData = data.length > 0 ? data[data.length - 1] : {};

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <CardWithTitle
        title={title}
        icon={cardIcon}
        buttonTitle="Edit"
        className={s.editButton}
        marginBottom={marginBottom}
        showButton={showButton}
        onButtonClick={handleOpenModal}
      >
        <ul className={s.listContainer}>
          {formFields.map((field, index) => (
            <li key={index} className={s.listItemContainer}>
              <p className={s.listItemTitle}>{field.label}:</p>
              <p className={s.listItemDescr}>
                {latestData[field.name] || "No data"}
              </p>
            </li>
          ))}
        </ul>

        {isModalOpen && (
          <Modal
            width="500px"
            title={title}
            onClose={handleCloseModal}
            onSave={handleSave}
          >
            <ReuseForm
              fields={formFields.map((field) => ({
                ...field,
                value: formState[field.name],
              }))}
              onChange={handleInputChange}
              onSubmit={handleSave}
            />
          </Modal>
        )}
      </CardWithTitle>
    </>
  );
};

export default CardWithFormModal;
