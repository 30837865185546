import React, { useState } from "react";
import NavigationBar from "../../PatientDetailGp/components/NavigationBar/NavigationBar.jsx";

import s from "./PatientMedication.module.css";
import AllergiesIntolerance from "../../PatientDetailGp/components/AllergiesIntolerance/AllergiesIntolerance";
import LongTermMedication from "./components/LongTermMedication/LongTermMedication.jsx";
import { MedicalHistoryCard } from "../../../../components/PatientDetailCardWithOverview/components/PatientOverviewModal/components/MedicalHistory/MedicalHistoryCard.jsx";
import PatientDetailCardWithOverview from "../../../../components/PatientDetailCardWithOverview/PatientDetailCardWithOverview.jsx";
import { Prescriptions } from "./components/Prescriptions/Prescriptions.jsx";
import AcuteMedication from "./components/ActuteMedication/AcuteMedication.jsx";
import { useAcuteMedications } from "../../../../hooks/useAcuteMedications.js";
import { mapMedications } from "./utils/medicationMapper.js";
import { useLongTermMedications } from "../../../../hooks/useLongTermMedications.js";

const PatientMedication = () => {
  const patientId = localStorage.getItem("kinesin-patientId");
  const { data: acuteMedications, isLoading: isLoadingAcute } =
    useAcuteMedications(patientId);
  const { data: longTermMedications, isLoading: isLoadingLongTerm } =
    useLongTermMedications(patientId);

  const [selectedLongTermRows, setSelectedLongTermRows] = useState({});
  const [selectedAcuteRows, setSelectedAcuteRows] = useState({});

  const selectedLongTermMedications = mapMedications(
    longTermMedications,
    selectedLongTermRows
  );
  const selectedAcuteMedications = mapMedications(
    acuteMedications,
    selectedAcuteRows
  );

  const selectedMedications = [
    ...selectedLongTermMedications,
    ...selectedAcuteMedications,
  ];

  return (
    <>
      <NavigationBar />
      <div style={{ margin: "0 auto" }}>
        <div className={s.wrapper}>
          <div className={s.firstColumnWrapper}>
            <>
              <PatientDetailCardWithOverview />
              <Prescriptions />
              <MedicalHistoryCard />
            </>
          </div>
          <div className={s.secondColumnWrapper}>
            <AllergiesIntolerance />
            <LongTermMedication
              medications={longTermMedications}
              isLoading={isLoadingLongTerm}
              selectedMedications={selectedMedications}
              selectedRows={selectedLongTermRows}
              setSelectedRows={setSelectedLongTermRows}
            />
            <AcuteMedication
              medications={acuteMedications}
              isLoading={isLoadingAcute}
              selectedMedications={selectedMedications}
              selectedRows={selectedAcuteRows}
              setSelectedRows={setSelectedAcuteRows}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientMedication;
