import { useEffect, useState } from "react";
import {
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import styles from "./ReportTable.module.css";
import { Loader } from "../../../../../../components/Loader/Loader";
import { GripVertical } from "lucide-react";
import { Pagination } from "@mui/material";
import SendSMSModal from "../SendSMSModal/SendSMSModal";

export const ReportTable = ({
  title,
  items,
  isLoading,
  columns,
  page,
  setPage,
  totalPages,
  totalItems,
  onExport,
  rows,
  setRows,
  reportType,
}) => {
  const [columnResizing, setColumnResizing] = useState({});
  const start = (page - 1) * 10 + 1;
  const end = rows === "All" ? totalItems : Math.min(page * rows, totalItems);
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    setRowSelection({});
  }, [reportType]);

  const table = useReactTable({
    data: items,
    columns,
    onRowSelectionChange: setRowSelection,
    state: {
      columnResizing,
      rowSelection,
    },
    getRowId: (row, index) => {
      if (reportType === "appointments") {
        return `${row.appointmentId}-${index}-${page}`;
      }
      return `${row.patientId}-${index}-${page}`;
    },
    onColumnResizingChange: setColumnResizing,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: "onChange",
    enableColumnResizing: true,
    defaultColumn: {
      minSize: 25,
      maxSize: 1000,
      size: 150,
    },
  });

  const [smsModalOpen, setSmsModalOpen] = useState(false);

  return (
    <>
      <Paper className={styles.reportTablePaper}>
        <div className={styles.reportTableWrapper}>
          <div className={styles.reportTableHeader}>
            <h3>{title}</h3>
            <div className={styles.reportTableHeaderButtons}>
              {(table.getIsSomeRowsSelected() ||
                table.getIsAllRowsSelected()) && (
                <button
                  className={styles.reportTableHeaderButton}
                  onClick={() => setSmsModalOpen(true)}
                >
                  Send SMS
                </button>
              )}
              <button
                className={styles.reportTableHeaderButton}
                onClick={onExport}
              >
                Export
              </button>
            </div>
          </div>
          <div className={styles.reportTable}>
            {isLoading && <Loader>Loading reports...</Loader>}

            {table.getRowModel().rows.length <= 0 && !isLoading && (
              <h4 className={styles.reportNoReportsWarning}>
                There are no reports matching current filters.
              </h4>
            )}

            {table.getRowModel().rows.length > 0 && !isLoading && (
              <TableContainer className={styles.tableContainer}>
                <Table stickyHeader>
                  <TableHead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <TableRow
                        key={headerGroup.id}
                        className={styles.tableHeaderRow}
                      >
                        {headerGroup.headers.map((header) => (
                          <TableCell
                            key={header.id}
                            className={styles.tableHeaderCell}
                            style={{
                              width: header.getSize(),
                              position: "relative",
                              overflow: "visible",
                              background: "transparent",
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {header.column.getCanResize() && (
                              <div
                                onMouseDown={header.getResizeHandler()}
                                onTouchStart={header.getResizeHandler()}
                                className={`${styles.resizer} ${
                                  header.column.getIsResizing()
                                    ? styles.isResizing
                                    : ""
                                }`}
                              >
                                <GripVertical className={styles.resizerIcon} />
                              </div>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableHead>
                  <TableBody>
                    {table.getRowModel().rows.map((row, index) => (
                      <TableRow key={index} className={styles.tableRow}>
                        {row.getVisibleCells().map((cell) => (
                          <TableCell
                            key={cell.id}
                            className={styles.tableCell}
                            style={{
                              width: cell.column.getSize(),
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                <div className={styles.paginationWrapper}>
                  <p className={styles.resultsInfo}>
                    {start}-{end} of {totalItems} results
                  </p>
                  <Pagination
                    count={totalPages}
                    onChange={(_event, value) => {
                      setPage(value);
                    }}
                    page={page}
                  />
                  <div className={styles.rowsPerPageWrapper}>
                    <span>Rows per page:</span>
                    <Select
                      size="small"
                      value={rows}
                      onChange={(e) => setRows(e.target.value)}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value="All">All</MenuItem>
                    </Select>
                  </div>
                </div>
              </TableContainer>
            )}
          </div>
        </div>
      </Paper>
      {smsModalOpen && (
        <SendSMSModal
          onClose={() => setSmsModalOpen(false)}
          selectedRows={rowSelection}
          reportType={reportType}
        />
      )}
    </>
  );
};
