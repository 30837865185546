import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useEffect, useRef } from "react";
import { debounce } from "lodash";

import style from "./AddLetterForm.module.css";

import { FormField } from "../form/FormField/FormField";
// import CustomCheckbox from "../helpers/CustomCheckbox/CustomCheckbox";
import moment from "moment";
import RichEditor from "../RichEditor/RichEditor";
import { useCreateLetter } from "../../hooks/useCreateLetter";
import { useSelector } from "react-redux";
import { createAxiosInstance } from "../../api/axiosConfig";
import toast from "react-hot-toast";

const formSchema = z.object({
  saveAsTemplate: z.boolean(),
  letter: z.string().min(1, { message: "Please provide content for letter." }),
  title: z.string().min(1, { message: "Please provide letter title." }),
  type: z.string().min(1, { message: "Please select letter type." }),
});

const downloadLetter = async (id, tenantVal, patientId) => {
  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.get(
      `${tenantVal}/patientletter/download/${id}/patient/${patientId}`,
      {
        responseType: "blob",
      }
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `letter-${id}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    toast.error(error);
  }
  console.log(id + " " + tenantVal + " " + patientId);

  //
};

export const AddLetterForm = ({
  patient,
  onSuccess,
  // onSaveForLater,
  consultationId,
  initialData,
  autoSave,
}) => {
  const savedId = useRef(null);
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      saveAsTemplate: false,
      letter: initialData?.text || "",
      title: initialData?.name || "",
      type: initialData?.letterType || "type-1",
    },
  });
  const createLetter = useCreateLetter();

  const handleFormSubmit = async (values) => {
    try {
      const res = await createLetter.mutateAsync({
        consultationId,
        id: initialData?.id || savedId.current,
        patientId: patient.id,
        tenant: tenantVal,
        text: values.letter,
        name: values.title,
        letterType: values.type,
      });

      savedId.current = res.id;
      onSuccess?.();
    } catch (error) {
      console.error("Submission error:", error);
    }
  };

  const debouncedSave = debounce((values) => {
    handleFormSubmit(values);
  }, 1000);

  useEffect(() => {
    if (autoSave) {
      const subscription = form.watch((values) => {
        debouncedSave(values);
      });
      return () => subscription.unsubscribe();
    }
  }, [autoSave, form, debouncedSave]);

  return (
    <FormProvider {...form}>
      <form
        className={style.addLetterForm}
        onSubmit={form.handleSubmit(handleFormSubmit)}
      >
        {/* <div className={style.templateSection}>
          <p className={style.addLetterFormLabel}>Templates (optional)</p>
          <div className={style.inputGroupGrid}>
            <button className={style.openModalButton}>Select template</button>
          </div>
        </div> */}
        <div className={style.inputGroupGrid}>
          <FormField name="type">
            <label className={style.addLetterFormLabel}>Type</label>
            <select
              as="select"
              className="form-select form-control custom-select"
              {...form.register("type")}
            >
              <option value="type-1">type 1</option>
              <option value="type-2">type 2</option>
            </select>
          </FormField>
          <div>
            <label className={style.addLetterFormLabel}>Patient</label>
            <input
              type="text"
              className="form-control"
              value={
                patient &&
                `${patient.firstName} ${patient.surname} (DOB - ${moment(
                  new Date(patient.dateOfBirth)
                ).format("DD/MM/YYYY")})`
              }
              disabled
            />
          </div>
        </div>
        <FormField name="title">
          <label className={style.addLetterFormLabel}>Title</label>
          <div className={style.cmInput}>
            <input className="form-control" {...form.register("title")} />
          </div>
        </FormField>
        <FormField name="letter">
          <label className={style.addLetterFormLabel}>Letter</label>
          <RichEditor
            editorValue={initialData?.text || ""}
            onChange={(value) => {
              form.setValue("letter", value);
            }}
          />
        </FormField>
        <div className={style.bottomSection}>
          <label className={style.formCheckboxLabel}>
            {/* <CustomCheckbox {...form.register("saveAsTemplate")} />
            Save as template */}
          </label>
          <div>
            {!autoSave && (
              <button
                className={style.sendButton}
                onClick={form.handleSubmit(handleFormSubmit)}
              >
                Send
              </button>
            )}
            <button
              className={`${style.openModalButton} ${style.downloadButton}`}
              onClick={() =>
                downloadLetter(initialData.id, tenantVal, patient.id)
              }
            >
              Download letter
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
