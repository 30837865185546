import dayjs from "dayjs";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import style from "./AddPrescriptionForm.module.css";

import { FormField } from "../form/FormField/FormField";
import moment from "moment";
import { SelectDrug } from "./SelectDrug/SelectDrug";
import { Trash } from "lucide-react";
import { useCreatePrescription } from "../../hooks/useCreatePrescription";
import { downloadPrescription, medicineSchema } from "./utils";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";

const formSchema = z.object({
  medicines: z.array(medicineSchema),
  instructions: z.string(),
});

export const AddPrescriptionForm = ({
  consultationId,
  onSuccess,
  onCancel,
  initialData = {},
  autoSave = false,
  initialMedications,
}) => {
  const queryClient = useQueryClient();
  const today = dayjs(new Date().setMinutes(0));
  const patientId = localStorage.getItem("kinesin-patientId");
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");
  const createPrescription = useCreatePrescription();

  const getDefaultMedicine = () => ({
    dosage: "",
    form: "",
    startDate: moment(new Date(today)).format("DD-MM-YYYY"),
    finishDate: moment(new Date(today)).format("DD-MM-YYYY"),
    duration: "",
    durationFormat: "",
    quantity: 0,
    usageType: "ACUTE",
    repeat: "",
    paymentType: "",
    isManual: false,
    tradeName: "",
    manufacturer: "",
    packSize: "",
    medicineId: "",
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      medicines: initialData.medicines ||
        initialMedications || [getDefaultMedicine()],
      instructions: initialData.instructions || "",
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "medicines",
  });

  if (!form) {
    console.error("Form is not initialized properly.");
    return null;
  }

  const handleSubmit = async (e) => {
    const values = form.getValues();

    const prescriptionData = {
      note: values.instructions,
      patientId: patientId,
      tenant: tenantVal,
      consultationId: consultationId,
      medications: values.medicines.map((item) => {
        const base = {
          consultationId: consultationId,
          id: item.id,
          dosage: item.dosage,
          form: item.form,
          startDate: moment(item.startDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
          finishDate: moment(item.finishDate, "DD-MM-YYYY").format(
            "YYYY-MM-DD"
          ),
          route: item.route,
          duration: item.duration,
          durationFormat: item.durationFormat,
          frequency: item.frequency,
          acuteLongTerm: item.usageType,
          repeat: Number(item.repeat),
          quantity: Number(item.quantity),
          patientId: patientId,
        };

        if (item.isManual) {
          return {
            ...base,
            tradeName: item.tradeName,
            manufacturer: item.manufacturer,
            packSize: item.packSize,
          };
        }

        return { ...base, medicationId: item.medicineId };
      }),
    };

    try {
      if (initialData?.id) {
        await createPrescription.mutateAsync({
          id: initialData.id,
          ...prescriptionData,
        });
      } else {
        await createPrescription.mutateAsync(prescriptionData);
      }
      onSuccess?.();

      if (!autoSave) {
        form.reset();
      }

      queryClient.invalidateQueries();
    } catch (error) {
      console.error("Submission error:", error);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        className={style.addLetterForm}
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <div
          className={`${style.medicineSectionWrapper} ${
            onCancel ? "" : style.medicineSectionWrapperWithoutBorder
          }`}
        >
          {fields.map((field, index) => (
            <div key={field.id} className={style.medicineSection}>
              <div className={style.medicineSectionHeader}>
                <p className={style.addLetterFormLabel}>Medicine</p>
                {index > 0 && (
                  <button
                    type="button"
                    onClick={() => remove(index)}
                    className={style.removeButton}
                    aria-label="Remove medicine"
                  >
                    <Trash width={18} height={18} />
                  </button>
                )}
              </div>
              <div className={style.drugSection}>
                <SelectDrug
                  index={index}
                  form={form}
                  initialData={
                    initialData.medicines?.[index] ??
                    initialMedications?.[index]
                  }
                />
              </div>

              <div className={style.inputGroupGrid}>
                <FormField name={`medicines.${index}.dosage`}>
                  <label className={style.addLetterFormLabel}>Dosage</label>
                  <input
                    className="form-control"
                    {...form.register(`medicines.${index}.dosage`)}
                  />
                </FormField>

                <FormField name={`medicines.${index}.form`}>
                  <label className={style.addLetterFormLabel}>Form</label>
                  <input
                    className="form-control"
                    {...form.register(`medicines.${index}.form`)}
                  />
                </FormField>

                <FormField name={`medicines.${index}.startDate`}>
                  <label className={style.addLetterFormLabel}>Start date</label>
                  <div className={style.addPregnancyDatePickerWrapper}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="DD-MM-YYYY"
                        defaultValue={today}
                        sx={{ width: "100%", height: "36px" }}
                        onChange={(newValue) => {
                          form.setValue(
                            `medicines.${index}.startDate`,
                            moment(new Date(dayjs(newValue))).format(
                              "DD-MM-YYYY"
                            )
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </FormField>
                <FormField name={`medicines.${index}.finishDate`}>
                  <label className={style.addLetterFormLabel}>End date</label>
                  <div className={style.addPregnancyDatePickerWrapper}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="DD-MM-YYYY"
                        defaultValue={today}
                        sx={{ width: "100%", height: "36px" }}
                        onChange={(newValue) => {
                          form.setValue(
                            `medicines.${index}.finishDate`,
                            moment(new Date(dayjs(newValue))).format(
                              "DD-MM-YYYY"
                            )
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </FormField>
                <FormField name={`medicines.${index}.endDate`}>
                  <label className={style.addLetterFormLabel}>Route</label>
                  <div className={style.addPregnancyDatePickerWrapper}>
                    <select
                      className="form-select form-control custom-select"
                      {...form.register(`medicines.${index}.route`)}
                    >
                      <option value=""></option>
                      <option value="Aural">Aural</option>
                      <option value="Inh">Inhalation</option>
                      <option value="IM">Intramuscular</option>
                      <option value="Intranasal">Intranasal</option>
                      <option value="IV">Intravenous</option>
                      <option value="PO">PO</option>
                      <option value="PR">PR</option>
                      <option value="PV">PV</option>
                      <option value="SC">Subcutaneous</option>
                      <option value="Sublingual">Sublingual</option>
                      <option value="Top">Topical</option>
                    </select>
                  </div>
                </FormField>
                <FormField name={`medicines.${index}.duration`}>
                  <label className={style.addLetterFormLabel}>
                    Duration (optional)
                  </label>
                  <input
                    className="form-control"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    {...form.register(`medicines.${index}.duration`, {
                      onChange: (e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      },
                    })}
                  />
                </FormField>

                <FormField name={`medicines.${index}.durationFormat`}>
                  <label className={style.addLetterFormLabel}>
                    Duration format (optional)
                  </label>
                  <select
                    className="form-select form-control custom-select"
                    {...form.register(`medicines.${index}.durationFormat`)}
                  >
                    <option value="days">Days</option>
                    <option value="months">Months</option>
                  </select>
                </FormField>

                <FormField name={`medicines.${index}.endDate`}>
                  <label className={style.addLetterFormLabel}>Frequency</label>
                  <div className={style.addPregnancyDatePickerWrapper}>
                    <select
                      className="form-select form-control custom-select"
                      {...form.register(`medicines.${index}.frequency`)}
                    >
                      <option value=""></option>
                      <option value="STAT">STAT</option>
                      <option value="OD">OD - Once Daily</option>
                      <option value="BD">BD - Twice Daily</option>
                      <option value="TDS">Three times daily</option>
                      <option value="QDS">Four times daily</option>
                      <option value="PRN">As required</option>
                      <option value="Once Weekly">Once Weekly</option>
                      <option value="Twice Weekly">Twice Weekly</option>
                      <option value="Three time weekly">
                        Three time weekly
                      </option>
                    </select>
                  </div>
                </FormField>

                <FormField name={`medicines.${index}.quantity`}>
                  <label className={style.addLetterFormLabel}>
                    Quantity (optional)
                  </label>
                  <input
                    className="form-control"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    {...form.register(`medicines.${index}.quantity`, {
                      onChange: (e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      },
                    })}
                  />
                </FormField>

                <FormField name={`medicines.${index}.usageType`}>
                  <label className={style.addLetterFormLabel}>
                    Acute / long term
                  </label>
                  <select
                    className="form-select form-control custom-select"
                    {...form.register(`medicines.${index}.usageType`)}
                  >
                    <option value="ACUTE">Acute</option>
                    <option value="LONGTERM">Long term</option>
                  </select>
                </FormField>

                <FormField name={`medicines.${index}.repeat`}>
                  <label className={style.addLetterFormLabel}>
                    Repeat (optional)
                  </label>
                  <input
                    className="form-control"
                    placeholder="How many times?"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    {...form.register(`medicines.${index}.repeat`, {
                      onChange: (e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      },
                    })}
                  />
                </FormField>
              </div>
            </div>
          ))}
        </div>
        <button
          type="button"
          onClick={() => append(getDefaultMedicine())}
          className={style.addAnotherMedicine}
        >
          Add another medicine +
        </button>
        <FormField name="instructions">
          <label className={style.addLetterFormLabel}>
            Instructions (optional)
          </label>
          <textarea
            className="form-control textareaform-control"
            style={{ minHeight: "126px" }}
            {...form.register("instructions")}
          />
        </FormField>
        <div
          className={`${style.bottomSection} ${
            onCancel ? "" : style.bottomSectionWithoutBorder
          }`}
        >
          <label className={style.formCheckboxLabel}></label>
          <div>
            {onCancel && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onCancel?.();
                }}
                className={style.openModalButton}
                type="button"
              >
                Cancel
              </button>
            )}
            <button
              type="button"
              onClick={form.handleSubmit(handleSubmit)}
              disabled={createPrescription.isPending}
              className={style.sendButton}
            >
              {createPrescription.isPending ? "Submitting..." : "Save"}
            </button>
          </div>
        </div>
      </form>
      {initialData.filePath && initialData.id && (
        <button
          className={`${style.openModalButton} ${style.downloadButton}`}
          onClick={() =>
            downloadPrescription(initialData.id, tenantVal, patientId)
          }
        >
          Download prescription
        </button>
      )}
    </FormProvider>
  );
};
