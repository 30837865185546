import React, { useEffect, useState } from "react";
import "../../../assets/css/allied_settings.css";
import Tab from "../../../components/SettingsTab/Tab";
import { createAxiosInstance } from "../../../api/axiosConfig";
import {
  HEADER_FOOTER_CONTROLER_URL,
  PRACTCE_DETAILS_CONTROLER_URL,
} from "../../../api/Service";
import { useDispatch, useSelector } from "react-redux";
import { Toaster, toast } from "react-hot-toast";
import { logout } from "../../../redux/auth/AuthSlice";
import { removeuser } from "../../../redux/user/userSlice";
import { logoutFromHelper } from "../../../api/Helper";
import { useNavigate } from "react-router-dom";
import { useAdminView } from "../../../hooks/useAdminView";

const HeaderFooter = () => {
  useAdminView();

  const [headerContent, setHeaderContent] = useState("");
  const [footerContent, setFooterContent] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let tenantVal = useSelector((state) => state.auth.user?.tenant);
  if (!tenantVal) tenantVal = localStorage.getItem("kinesin-tenant");
  const [displayImage, setDisplayImage] = useState(null);
  const [headerFooter, setHeaderFooter] = useState({});

  const [base64Image, setBase64Image] = useState("");

  const setHeaderInfo = (value) => {
    console.log("HEADER AND FOOTER HEADER " + value);
    setHeaderContent(value);
  };

  const setFooterInfo = (value) => {
    console.log("HEADER AND FOOTER fOOTER " + value);
    setFooterContent(value);
  };

  const handleSubmit = async () => {
    try {
      const axiosInstance = createAxiosInstance();

      const requestData = headerFooter;
      requestData.tenant = tenantVal;
      requestData.header = headerContent;
      requestData.footer = footerContent;

      await axiosInstance.post(
        `${tenantVal}${HEADER_FOOTER_CONTROLER_URL}`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      errorHandling(error);
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const axiosInstance = createAxiosInstance();
        const response = await axiosInstance.get(
          `${tenantVal}${PRACTCE_DETAILS_CONTROLER_URL}`
        );
        console.log("response Data practice details page=>", response);
        const practiceData = response.data;
        const imageData = practiceData[0].practicePicture;
        setBase64Image(imageData);
        setDisplayImage(imageData);
      } catch (error) {
        errorHandling(error);
      }
    };
    fetchDetails();
  }, [tenantVal]);

  useEffect(() => {
    const fetchHeaderFooterDetails = async () => {
      try {
        const axiosInstance = createAxiosInstance();
        const response = await axiosInstance.get(
          `${tenantVal}${HEADER_FOOTER_CONTROLER_URL}`
        );
        const headerFooterData = response.data;
        setHeaderFooter(headerFooterData);

        setHeaderContent(headerFooterData.header);
        setFooterContent(headerFooterData.footer);

        console.log(headerFooter);
      } catch (error) {
        errorHandling(error);
      }
    };
    fetchHeaderFooterDetails();
  }, [tenantVal]);

  const errorHandling = (error) => {
    console.log(error);
    toast.error(error.message);
    if (error && error.response && error.response.status) {
      if (error.response.status === 403) {
        logoutFromHelper();
        dispatch(logout());
        dispatch(removeuser());
        navigate("/logout");
      }
    }
  };

  return (
    <div>
      <div className="dashBcontbody">
        <div className="signup_threebox practice_detailssect mt-0">
          <Tab />
          <div className="tab-content ptdetalistabcont" id="pills-tabContent">
            <div className=" show active sectflex">
              <div className="dtl_uploadimgsect">
                <label className="frompadding ">Logo</label>

                <div className="upimgbox">
                  {base64Image ? (
                    <div className="cercelimg">
                      <img
                        // src={URL.createObjectURL(selectedImage)}
                        src={base64Image}
                        className="selected-img "
                      />
                    </div>
                  ) : (
                    <div className="cercelimg">
                      <img src={displayImage} className="selected-img" />
                    </div>
                  )}
                </div>
              </div>

              <div className="tabdtformsect signup_threebox addpatientlist">
                <div className="row">
                  <div className="col-xl-12 mb-12 frompadding">
                    <label className="requiredValidator">Header</label>
                    <textarea
                      placeholder="Type footer here"
                      className="textarea_default form-control textareaform-control"
                      type="text"
                      rows="30"
                      maxLength="2500"
                      value={headerContent}
                      onChange={(e) => {
                        const newNote = e.target.value;
                        setHeaderInfo(newNote);
                      }}
                    />
                  </div>
                  <div className="col-xl-12 mb-12 frompadding">
                    <label className="requiredValidator">Footer</label>
                    <textarea
                      placeholder="Type footer here"
                      className="textarea_default form-control textareaform-control"
                      type="text"
                      rows="30"
                      maxLength="2500"
                      value={footerContent}
                      onChange={(e) => {
                        const newNote = e.target.value;
                        setFooterInfo(newNote);
                      }}
                    />
                  </div>

                  <div className="col-xl-12 mt-3">
                    <div className="btnxscenter">
                      <button
                        className="custom_btn savwidth"
                        onClick={() => handleSubmit()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Toaster position="top-center" reverseOrder={false} />
      </div>
    </div>
  );
};

export default HeaderFooter;
