import { useState } from "react";
import { Toaster } from "react-hot-toast";
import { Loader2 } from "lucide-react";
import { Image } from "react-bootstrap";

import srcicon from "../../../../../../assets/images/srcicon.svg";
import EmptyState from "../../../../../../assets/images/medications-empty-state.svg";
import styles from "./DrugsTable.module.css";
import { SortByButton } from "./SortByButton";
import { Checkbox } from "@mui/material";

export const DrugsTable = ({
  items,
  tableName,
  isLoading,
  emptyTitle,
  selectedRows,
  setSelectedRows,
}) => {
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState("newest-first");

  const sortedAndFilteredItems =
    items
      ?.map((item) => ({
        ...item,
        medications:
          item.medicines?.map((medicine) => medicine.tradeName).join(", ") ||
          "N/A",
      }))
      .filter((item) =>
        item.medications.toLowerCase().includes(search.toLowerCase())
      ) || [];

  if (items?.length === 0) {
    return (
      <div className={styles.emptyState}>
        <Image src={EmptyState} alt="category" />
        <div>
          <p className={styles.emptyStateTitle}>
            {emptyTitle || "No Acute prescriptions"}
          </p>
          <p className={styles.emptyStateText}>
            {search
              ? "Try adjusting your search or filters to find what you're looking for."
              : "Once the patient receives a prescription, it will appear here."}
          </p>
        </div>
      </div>
    );
  }

  const renderTableContent = () => {
    if (isLoading) {
      return (
        <div className={styles.emptyState2}>
          <Loader2 className={styles.loadingSpinner} size={24} />
          <p className={styles.emptyStateTitle2}>Loading data...</p>
          <p className={styles.emptyStateText2}>
            Please wait while we fetch the medical history.
          </p>
        </div>
      );
    }

    if (sortedAndFilteredItems.length === 0) {
      return (
        <div className={styles.emptyState2}>
          <p className={styles.emptyStateTitle2}>No items found</p>
          <p className={styles.emptyStateText2}>
            {search
              ? "Try adjusting your search or filters to find what you're looking for."
              : "There are no items to display at this time."}
          </p>
        </div>
      );
    }

    return (
      <ul className={styles.tableItems}>
        {sortedAndFilteredItems.map((item) => (
          <li className={styles.tableItem} key={item.id}>
            <p aria-describedby={`${tableName}-action-checkbox`}>
              <Checkbox
                sx={{
                  "&.Mui-checked": {
                    color: "var(--green-900)",
                  },
                  "&.MuiCheckbox-indeterminate": {
                    color: "var(--green-900)",
                  },
                }}
                checked={!!selectedRows[item.id]}
                onChange={(e) =>
                  setSelectedRows((prev) => ({
                    ...prev,
                    [item.id]: e.target.checked,
                  }))
                }
              />
            </p>
            <p aria-describedby={`${tableName}-table-drugs-name`}>
              {item.tradeName}
            </p>
            <p>{item.dosage}</p>
            <p aria-describedby={`${tableName}-table-doctor`}>
              {item.frequency}
            </p>
            <p>{item.route}</p>
            <p aria-describedby={`${tableName}-table-doctor`}>
              {item.quantity}
            </p>
            <p aria-describedby={`${tableName}-table-doctor`}>{item.repeat}</p>
            <p aria-describedby={`${tableName}-table-doctor`}>
              {item.startDate}
            </p>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className={styles.tableWarpper}>
        <div className={styles.table}>
          <div className={styles.tableTopBarWrapper}>
            <div className={`${styles.searchWrapper} search_bar`}>
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                disabled={isLoading}
              />
              <img src={srcicon} alt="" />
            </div>
            <SortByButton
              selectedItem={sorting}
              setSelectedItem={setSorting}
              disabled={isLoading}
            />
          </div>
          <div className={styles.tableHeader}>
            <div id={`${tableName}-action-checkbox`}>
              <Checkbox
                sx={{
                  "&.Mui-checked": {
                    color: "var(--green-900)",
                  },
                  "&.MuiCheckbox-indeterminate": {
                    color: "var(--green-900)",
                  },
                }}
                checked={
                  Object.values(selectedRows).every(Boolean) &&
                  Object.keys(selectedRows).length ===
                    sortedAndFilteredItems.length
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    const newSelectedRows = items.reduce(
                      (acc, item) => ({ ...acc, [item.id]: true }),
                      {}
                    );
                    setSelectedRows(newSelectedRows);
                  } else {
                    setSelectedRows({});
                  }
                }}
                indeterminate={
                  Object.values(selectedRows).some((selected) => selected) &&
                  Object.keys(selectedRows).length !==
                    sortedAndFilteredItems.length
                }
              />
            </div>
            <p id={`${tableName}-table-date-prescribed`}>Drug name</p>
            <p id={`${tableName}-table-drugs-form`}>Dosage</p>
            <p id={`${tableName}-table-drugs-form`}>Frequency</p>
            <p id={`${tableName}-table-date-manufacturer`}>Route</p>
            <p id={`${tableName}-table-end-date`}>Quantity</p>
            <p id={`${tableName}-table-end-date`}>Repeats</p>
            <p id={`${tableName}-table-start-date`}>Start date</p>
          </div>
          {renderTableContent()}
        </div>
      </div>
    </>
  );
};
