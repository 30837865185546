import React from "react";
import s from "./AcuteMedication.module.css";
import { Toaster } from "react-hot-toast";
import { DrugsTable } from "../DrugsTable/DrugsTable.jsx";
import { AddPrescriptionModal } from "../../../../../../components/AddPrescriptionModal/AddPrescriptionModal.jsx";
import { PlusIcon } from "lucide-react";

const AcuteMedication = ({
  medications,
  isLoading,
  selectedMedications,
  selectedRows,
  setSelectedRows,
}) => {
  return (
    <div className={s.longTermWrapper}>
      <Toaster position="top-center" reverseOrder={false} />
      <div className={s.topWrapper}>
        <h3 className={s.title}>Acute medications</h3>

        {Object.keys(selectedRows).length > 0 &&
          Object.values(selectedRows).some((selected) => selected) && (
            <AddPrescriptionModal
              buttonIcon={<PlusIcon width={18} height={18} />}
              initialMedications={selectedMedications}
            />
          )}
      </div>

      <DrugsTable
        items={medications}
        isLoading={isLoading}
        tableName="acute"
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

export default AcuteMedication;
