import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";
import { useQueryClient } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";

export const useUpdateFamilyPaymentStatus = (onSuccess) => {
  const queryClient = useQueryClient();

  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error payment status: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useMutation({
    mutationFn: async ({ id, status }) => {
      if (status === "PAID") {
        const axiosInstance = createAxiosInstance();
        const response = await axiosInstance.post(
          `${tenantVal}/consultationpayment/confirmpayment/paymentdueid/${id}`
        );
        return response.data;
      }

      if (status === "DUE") {
        const axiosInstance = createAxiosInstance();
        const response = await axiosInstance.post(
          `${tenantVal}/consultationpayment/confirmpayment/paymentdueid/${id}`
        );
        return response.data;
      }
    },
    onSuccess: () => {
      toast.success("Data saved successfully!");
      queryClient.invalidateQueries([
        "patientDuePayments",
        "patientPayments",
        "patientFamilyPayments",
      ]);
      onSuccess?.();
    },
    onError: handleNetworkError,
  });
};
