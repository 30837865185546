import moment from "moment";
import { useMainService } from "../../../../../hooks/useMainService";
import styles from "./PatientPaymentsTable.module.css";
import { Download } from "lucide-react";
import toast from "react-hot-toast";
import { createAxiosInstance } from "../../../../../api/axiosConfig";
import { useSelector } from "react-redux";
import { useActiveConsultationPrescription } from "../../../../../hooks/useActiveConsultationPrescription";

export const PatientPaymentsTableItem = ({ item }) => {
  const { data: mainService } = useMainService(
    item?.consultation?.mainPracticeServiceId
  );
  const { data: activeConsultationPrescription } =
    useActiveConsultationPrescription(item?.consultation?.id);

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");
  const patientId = localStorage.getItem("kinesin-patientId");

  const hasPrescriptionToDownload =
    activeConsultationPrescription?.at(0) &&
    activeConsultationPrescription?.at(0)?.filePath;

  const handleDownload = async (item) => {
    try {
        console.log(item);
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/consultationpayment/receipt/${item.id}/patient/${patientId}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `receipt-${item.id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      toast.error("Error downloading receipt");
    }
  };

  return (
      <li className={styles.tableItem}>
          <p aria-describedby="report-table-payment-date">
              {item?.paymentDate
                  ? moment(item?.paymentDate, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY")
                  : "-"}
          </p>
          <p aria-describedby="report-table-service-name">
              {item?.serviceName || "-"}
          </p>
          <p aria-describedby="report-table-service-name">
              {item?.consultationDate
                  ? moment(item?.consultationDate, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY")
                  : "-"}</p>
          <p aria-describedby="report-table-amount-owed">
              €{item?.amountPaid ?? 0}
          </p>

              <button
                  className={styles.downloadLink}
                  onClick={() => handleDownload(item)}
                  aria-label="Download prescription"
              >
                  <Download width={16} height={16}/>
              </button>

      </li>
  );
};
