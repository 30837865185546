import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addPatientFullName,
  addPatientId,
} from "../../redux/patient/PatientSlice";
import s from "./PatientDetailsButton.module.css";

const PatientDetailButton = ({ patientId, patientFullName }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userType = useSelector((state) => state.auth.user?.type || "");

  const handleClick = () => {
    dispatch(addPatientId(patientId));
    localStorage.setItem("kinesin-patientId", patientId);
    dispatch(addPatientFullName(patientFullName));
    if (userType === "GP") {
      navigate("/patient-detail-gp");
    } else {
      navigate("/patient-detail");
    }
  };

  return (
    <button className={s.button} onClick={handleClick}>
      {patientFullName}
    </button>
  );
};

export default PatientDetailButton;
