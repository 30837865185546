import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { Field } from "formik";
import React, { useState } from "react";
import PasswordVisible from "../../../../assets/images/auth/password-visible.svg";
import PasswordHidden from "../../../../assets/images/auth/password-hidden.svg";

const PasswordInput = ({ id, name }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Field
      as={OutlinedInput}
      id={id}
      sx={{
        background: "white",
        borderRadius: "12px",
        height: "48px",
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#006546",
          },
        },
      }}
      type={showPassword ? "text" : "password"}
      name={name}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            size="small"
            aria-label={
              showPassword ? "hide the password" : "display the password"
            }
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            onMouseUp={handleMouseUpPassword}
            edge="end"
          >
            {showPassword ? (
              <img src={PasswordHidden} alt="Password hidden" />
            ) : (
              <img src={PasswordVisible} alt="Password visible" />
            )}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default PasswordInput;
