import consult from "../../../../../assets/images/navbar/consult.svg";
import details from "../../../../../assets/images/navbar/details.svg";
import medications from "../../../../../assets/images/navbar/medications.svg";
import appointments from "../../../../../assets/images/navbar/appointments.svg";
import letters from "../../../../../assets/images/letter.svg";
import investigations from "../../../../../assets/images/investigations.svg";
import accounting from "../../../../../assets/images/accounting.svg";
import medicoLegal from "../../../../../assets/images/navbar/medicoLegal.svg";
import audit from "../../../../../assets/images/navbar/audit.svg";

export const menuItems = [
  { src: consult, label: "Patients", url: "/reports", className: "" },
  {
    src: medications,
    label: "Immunisations",
    url: "/reports/immunisations",
    className: "",
  },
  {
    src: details,
    label: "Prescriptions",
    url: "/reports/prescriptions",
    className: "",
  },
  {
    src: investigations,
    label: "Investigations",
    url: "/reports/investigations",
    className: "",
  },
  {
    src: letters,
    label: "Letters",
    url: "/reports/letters",
    className: "",
  },
  {
    src: accounting,
    label: "Accounting",
    url: "/reports/accounting",
    className: "",
  },
  {
    src: appointments,
    label: "Appointments",
    url: "/reports/appointments",
    className: "",
  },
  /*{
    src: medicoLegal,
    label: "Medico - Legal",
    url: "/reports/medico-legal",
    className: "",
  },*/
  {
    src: audit,
    label: "Audit Log",
    url: "/reports/audit-log",
    className: "",
  },
];
