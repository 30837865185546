import React, { Fragment, useEffect, useRef, useState } from "react";
import information from "../../../assets/images/information.svg";
import moment from "moment";
import vaccinesimg from "../../../assets/images/vaccinesimg.svg";
import toast, { Toaster } from "react-hot-toast";
import { createAxiosInstance } from "../../../api/axiosConfig";
import HorizontalCalendarNew1 from "../../../components/HorizontalCalendarNew1";
import { useLocation } from "react-router-dom";
import {
  GET_APPOINTMENTS_HOME_PAGE,
  PATIENT_CONTROLER_URL_ID,
  WHO_AM_I,
  PRACTICE_SERVICE_CONTROLER_URL,
  PRACTICE_HOURS_CONTROLER_URL,
} from "../../../api/Service";
import { useDispatch, useSelector } from "react-redux";
import { logoutFromHelper } from "../../../api/Helper";
import { logout } from "../../../redux/auth/AuthSlice";
import { removeuser } from "../../../redux/user/userSlice";
import { useNavigate } from "react-router-dom";
import AddAppointmentModal from "../../../components/Modal/AddAppointmentModal";
import $ from "jquery";
import { ConsultationsTable } from "../../pages/PatientGp/PatientConsultations/components/ConsultationsTable/ConsultationsTable";
import { Tasks } from "./components/Tasks";
import { useOpenPatientSummaryPage } from "../../../Utils/openPatientSummaryPage";
import { AppointmentBox } from "./components/AppointmentBox";

const AlliedHome = () => {
  const [selectedDate, setSelectedDate] = useState([]);
  const [calenderDate, setcalenderDate] = useState(moment().date());
  const [calenderMonth, setcalenderMonth] = useState(moment().month() + 1);
  const [calenderYear, setcalenderYear] = useState(moment().year());
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [consultationLength, setConsultationLength] = useState(0);
  const [freeTime, setFreeTime] = useState(0);
  const [selectedDateForAppointment, setSelectedDateForAppointment] = useState(
    new Date()
  );
  const [staffMemberId, setStaffMemberId] = useState();
  const [practiceServices, setPracticeServices] = useState([]);
  const [startDateForAppointment, setStartDateForAppointment] = useState();
  const [startTimeForAppointment, setStartTimeForAppointment] = useState();

  const [allDayStartTimeAndEndTime, setAllDayStartTimeAndEndTime] = useState(
    []
  );

  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const [indexAndPatient, setIndexAndPatient] = useState({});

  const [x, setX] = useState({
    slotInterval: 15,
    openTime: "",
    closeTime: "",
  });
  const [allTimes, setAllTimes] = useState([]);
  const swiperRef = useRef(null);

  const fetchHours = async () => {
    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${PRACTICE_HOURS_CONTROLER_URL}`
      );
      setAllDayStartTimeAndEndTime(response.data);
    } catch (error) {
      errorHandling(error);
    }
  };

  const updateSlots = async () => {
    if (allDayStartTimeAndEndTime) {
      let ans = allDayStartTimeAndEndTime.filter(
        (f) =>
          f.day &&
          f.day.toLowerCase() ===
            moment(selectedDate).format("dddd").toLowerCase()
      )[0];

      if (ans && ans.startTime && ans.endTime) {
        let oTime = moment("Sat Mar 02 2024 " + ans.startTime).format(
          "HH:mm A"
        );
        let cTime = moment("Sat Mar 02 2024 " + ans.endTime).format("HH:mm A");
        setX({
          slotInterval: 15,
          openTime: oTime,
          closeTime: cTime,
        });
        const startTime = moment(oTime, "h:mm A");
        const endTime = moment(cTime, "h:mm A");
        let allTimesAli = [];
        while (startTime <= endTime) {
          allTimesAli.push(startTime.format("HH:mm"));
          startTime.add(x.slotInterval, "minutes");
        }
        setAllTimes(allTimesAli);
      }
    }
  };

  const fetchProfileInfo = async () => {
    try {
      const axiosInstance = createAxiosInstance(true);
      const response = await axiosInstance.get(`${WHO_AM_I}`);
      if (
        response &&
        response.data &&
        response.data.staffMember &&
        response.data.staffMember.id
      ) {
        setStaffMemberId(
          response && response.data && response.data.staffMember
            ? response.data.staffMember.id
            : ""
        );
      }
    } catch (error) {
      errorHandling(error);
    }
  };

  const fetchPracticeServices = async () => {
    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${PRACTICE_SERVICE_CONTROLER_URL}`
      );
      let t = [{ label: "Type of Consultation*", value: "" }];
      if (response && response.data) {
        response.data.forEach((element) => {
          t.push({ label: element.name, value: element.id });
        });
      }

      setPracticeServices(t);
    } catch (error) {
      errorHandling(error);
    }
  };

  let tenantVal = useSelector((state) => state.auth.user?.tenant);
  if (!tenantVal) tenantVal = localStorage.getItem("kinesin-tenant");

  const getPatientById = async (id, reasonForVisit) => {
    if (!id) return;

    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${PATIENT_CONTROLER_URL_ID}${id}`
      );
      if (response && response.data) {
        response.data.reasonForVisit = reasonForVisit;
        setSelectedPatient(response.data);
      }
    } catch (error) {
      errorHandling(error);
    }
  };

  const getbystaffmemberid = async (date) => {
    if (!date || !x.openTime) return;

    var startTimeApt = moment(date).format("yyyy-MM-DD");
    var endTimeApt = moment(date).format("yyyy-MM-DD");
    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${GET_APPOINTMENTS_HOME_PAGE}` +
          "?startDate=" +
          startTimeApt +
          "&endDate=" +
          endTimeApt
      );

      let dddd = new Date(date);
      dddd.setHours(moment("Sat Mar 02 2024 " + x.openTime).format("HH"));
      dddd.setMinutes(moment("Sat Mar 02 2024 " + x.openTime).format("mm"));
      dddd.setSeconds(0);
      let finalResponse = [];

      if (response.data) {
        response.data.forEach((d) => {
          if (
            d.startTime &&
            moment(moment(d.startTime).format("yyyy-MM-DD")).isSame(
              moment(moment(dddd).format("yyyy-MM-DD"))
            )
          ) {
            if (moment(d.startTime).isSameOrAfter(moment(dddd)))
              finalResponse.push(d);
          }
        });
      }

      setConsultationLength(finalResponse ? finalResponse.length : 0);

      const sTime = moment(x.openTime, "h:mm A");
      const eTime = moment(x.closeTime, "h:mm A");
      let patientArray = [];

      let num = 0;
      let indexAndPatient = {};
      while (sTime <= eTime) {
        let p;
        if (finalResponse && finalResponse.length > 0) {
          p = finalResponse.filter(
            (pt) =>
              moment(pt.startTime).format("HH:mm") === sTime.format("HH:mm")
          );
          if (p && p[0]) {
            patientArray.push(p[0]);
            if (p[0].durationInMins === 15) num = 0;
            else if (p[0].durationInMins === 30) num = 1;
            else if (p[0].durationInMins === 45) num = 2;
            else if (p[0].durationInMins === 60) num = 3;

            p[0].isDisplay = true;
            indexAndPatient[sTime.format("HH:mm")] = p[0];
          } else {
            if (num === 0)
              indexAndPatient[sTime.format("HH:mm")] = { isDisplay: true };
            else {
              indexAndPatient[sTime.format("HH:mm")] = { isDisplay: false };
              num--;
            }
          }
        } else {
          indexAndPatient[sTime.format("HH:mm")] = { isDisplay: true };
        }
        sTime.add(x.slotInterval, "minutes");
        setStartTimeForAppointment();
      }
      setIndexAndPatient(indexAndPatient);

      let countFreeTime = 0;
      if (indexAndPatient) {
        for (const [key, value] of Object.entries(indexAndPatient)) {
          if (value && value.isDisplay && !value.durationInMins)
            countFreeTime++;
        }
      }
      setFreeTime(countFreeTime);
      if (patientArray && patientArray.length > 0) {
        let list;
        for (let i = 0; i < patientArray.length; i++) {
          if (
            patientArray[i] &&
            patientArray[i].startTime &&
            new moment(new Date()).isBefore(moment(patientArray[i].startTime))
          ) {
            list = patientArray[i];
            break;
          }
        }

        if (list && list.patient && list.patient.id) {
          getPatientById(
            list && list.patient ? list.patient.id : "",
            list && list.reasonForVisit ? list.reasonForVisit : ""
          );
          setSelectedAppointment(list);
        } else {
          setSelectedPatient(null);
        }
      }
    } catch (error) {
      errorHandling(error);
    }
  };
  useEffect(() => {
    fetchHours();
    fetchProfileInfo();
    fetchPracticeServices();
  }, [tenantVal]);

  useEffect(() => {
    fetchHours();
    updateSlots();
    const swiperInstance = swiperRef.current?.swiper;

    if (swiperInstance) {
      swiperInstance.slideTo(calenderDate - 1);
    }
  }, [calenderDate, calenderMonth, calenderYear]);
  useEffect(() => {
    const formattedDate = new Date(
      calenderYear,
      calenderMonth - 1,
      calenderDate,
      18,
      30,
      0
    ).toISOString();
    // Updating the selectedDate state
    setSelectedDate(formattedDate);
  }, [calenderDate, calenderYear, calenderMonth]);
  useEffect(() => {
    if (state && state.showToast) {
      // Display toast message
      toast.success("Profile updated successfully.");
    }
  }, [state]);
  useEffect(() => {
    if (selectedDate) {
      getbystaffmemberid(selectedDate);
    }
  }, [x]);

  useEffect(() => {
    updateSlots();
  }, [allDayStartTimeAndEndTime]);

  const openPatientSummaryPage = useOpenPatientSummaryPage();

  const errorHandling = (error) => {
    if (error && error.response) {
      if (error.response.data && error.response.data.message)
        toast.error(error.response.data.message);

      if (error.response.status && error.response.status == 403) {
        logoutFromHelper();
        dispatch(logout());
        dispatch(removeuser());
        navigate("/logout");
      }
    }
  };

  const openAddAppointmentModal = () => {
    var iscurrentDate = moment(selectedDate)?.isBefore(new Date(), "day");
    if (iscurrentDate) return;

    setTimeout(() => {
      $("#idButtonAddAppointmentPopup").trigger("click");
    }, 100);
  };
  const displayAge = (dob) => {
    if (!dob) return "";

    let aa = dob.split("-");
    if (aa && aa.length == 3) {
      return aa[2] + "/" + aa[1] + "/" + aa[0];
    } else {
      return dob;
    }
  };

  // Component to count the words from notes and show remaining words or text into tooltip
  const ParagraphWithHover = ({ text }) => {
    const [showFullText, setShowFullText] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const words = text.split(" ");

    const wordLimit = 15;
    const firstTwoLinesWords = words.slice(0, wordLimit);
    const remainingWords = words.slice(wordLimit);

    // Function to handle hover and show tooltip
    const handleMouseOver = () => {
      setShowTooltip(true);
    };

    // Function to handle mouse leave and hide tooltip
    const handleMouseLeave = () => {
      setShowTooltip(false);
    };
    return (
      <h6 onMouseEnter={handleMouseOver} onMouseLeave={handleMouseLeave}>
        {showFullText ? (
          text
        ) : (
          <>
            {firstTwoLinesWords.join(" ")}
            {remainingWords.length > 0 && "..."}
            {showTooltip && <span className="hover-text">{text}</span>}
          </>
        )}
      </h6>
    );
  };

  return (
    <>
      <div className="dashBcontbody alliedhome">
        <Toaster position="top-center" reverseOrder={false} />
        <div className="patientSummary">
          <div className="payments_box">
            <div className="summary_whitebox">
              <div className="summary_heading">
                <h3 className="title-wrap">Appointments</h3>
                <button
                  id="idButtonAddAppointmentPopup"
                  data-bs-toggle="modal"
                  data-bs-target="#addAppointment_modal"
                  style={{
                    height: 0,
                    padding: 0,
                    borderRightWidth: 0,
                    borderLeftWidth: 0,
                  }}
                ></button>
              </div>
              <div className="allied-appointments-wrap">
                <div className="payments_boxbody">
                  <HorizontalCalendarNew1
                    selectedDate={calenderDate}
                    selectedMonth={calenderMonth}
                    selectedYear={calenderYear}
                    setSelectedDate={setcalenderDate}
                    setSelectedMonth={setcalenderMonth}
                    setSelectedYear={setcalenderYear}
                  />
                </div>
                <div className="timetablebox">
                  <div className="timetablebordarbox">
                    <div className="summary_heading timetablhead">
                      <h6>
                        {consultationLength}{" "}
                        {consultationLength && consultationLength > 0
                          ? "Consultations"
                          : "Consultation"}{" "}
                      </h6>
                      <h6>
                        {freeTime}{" "}
                        {freeTime && freeTime > 0 ? "Free slots" : "Free slot"}
                      </h6>
                    </div>
                    <div className="timelistbox">
                      <div className="timecount">
                        {allTimes.map((time, index) => (
                          <div className="timenumbar" key={index}>
                            {time}
                          </div>
                        ))}
                      </div>
                      <ul>
                        {allTimes.map((list, index) => (
                          <Fragment key={index}>
                            {indexAndPatient &&
                            indexAndPatient[list] &&
                            indexAndPatient[list].isDisplay ? (
                              <AppointmentBox
                                key={index}
                                refetchAppointments={() => {
                                  getbystaffmemberid(selectedDate);
                                }}
                                className={
                                  list &&
                                  indexAndPatient &&
                                  indexAndPatient[list] &&
                                  indexAndPatient[list].durationInMins
                                    ? indexAndPatient &&
                                      indexAndPatient[list] &&
                                      indexAndPatient[list].durationInMins ===
                                        15
                                      ? "homepage-duration-15"
                                      : indexAndPatient[list].durationInMins ===
                                        30
                                      ? "homepage-duration-30"
                                      : indexAndPatient[list].durationInMins ===
                                        45
                                      ? "homepage-duration-45"
                                      : "homepage-duration-60"
                                    : "homepage-duration-15"
                                }
                                handleClick={() => {
                                  if (
                                    indexAndPatient &&
                                    indexAndPatient[list] &&
                                    indexAndPatient[list].patient &&
                                    indexAndPatient[list].patient.id
                                  ) {
                                    getPatientById(
                                      indexAndPatient &&
                                        indexAndPatient[list] &&
                                        indexAndPatient[list].patient
                                        ? indexAndPatient[list].patient.id
                                        : "",
                                      indexAndPatient &&
                                        indexAndPatient[list] &&
                                        indexAndPatient[list].reasonForVisit
                                        ? indexAndPatient[list].reasonForVisit
                                        : ""
                                    );
                                    setSelectedAppointment(
                                      indexAndPatient && indexAndPatient[list]
                                    );
                                  } else {
                                    setStartDateForAppointment(
                                      moment(new Date(selectedDate)).format(
                                        "YYYY-MM-DD"
                                      )
                                    );
                                    setStartTimeForAppointment(allTimes[index]);
                                    setSelectedDateForAppointment(
                                      new Date(selectedDate)
                                    );

                                    if (
                                      moment(
                                        calenderYear +
                                          "-" +
                                          calenderMonth +
                                          "-" +
                                          calenderDate +
                                          " " +
                                          allTimes[index]
                                      )?.isBefore(moment(new Date()))
                                    ) {
                                      toast.error(
                                        "Oops! you can not create an appointment for time slot that is already passed."
                                      );
                                    } else {
                                      openAddAppointmentModal();
                                    }
                                  }
                                }}
                                handleDoubleClick={() => {
                                  if (
                                    list &&
                                    indexAndPatient &&
                                    indexAndPatient[list] &&
                                    indexAndPatient[list].patient &&
                                    indexAndPatient[list].patient.id
                                  )
                                    openPatientSummaryPage(
                                      list &&
                                        indexAndPatient &&
                                        indexAndPatient[list] &&
                                        indexAndPatient[list].patient &&
                                        indexAndPatient[list].patient.id
                                        ? indexAndPatient[list].patient.id
                                        : "",
                                      list &&
                                        indexAndPatient &&
                                        indexAndPatient[list] &&
                                        indexAndPatient[list].patient &&
                                        indexAndPatient[list].patient.firstName
                                        ? indexAndPatient[list].patient
                                            .firstName +
                                            " " +
                                            indexAndPatient[list].patient
                                              .surname
                                        : ""
                                    );
                                }}
                                item={indexAndPatient[list]}
                                selectedAppointment={selectedAppointment}
                                indexAndPatient={indexAndPatient}
                              />
                            ) : (
                              ""
                            )}
                          </Fragment>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="informationSummary patient-detail-summary">
            <div className="summary_whitebox">
              <div className="summary_heading">
                <div className="sum_headicon">
                  <img src={information} alt="" />
                </div>
                <h2>Patient Information Summary</h2>
              </div>
              {selectedPatient ? (
                <div className="summary_bxoddy">
                  <div className="summary_colorbg">
                    <div className="sumarysmsbtnbox">
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#sendSma_modal"
                        onClick={(e) => e.stopPropagation()}
                      >
                        Send SMS
                      </button>
                    </div>
                    <div className="summaryImg">
                      <span
                        className="user-profile-image"
                        onClick={(e) => {
                          e.preventDefault();
                          openPatientSummaryPage(
                            selectedPatient.id,
                            selectedPatient.firstName
                              ? selectedPatient.firstName +
                                  " " +
                                  selectedPatient.surname
                              : ""
                          );
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {selectedPatient &&
                        selectedPatient.firstName.trim() &&
                        selectedPatient.surname.trim()
                          ? selectedPatient.firstName.trim()[0] +
                            selectedPatient.surname.trim()[0]
                          : (selectedPatient && selectedPatient.firstName.trim()
                              ? selectedPatient.firstName.trim()[0]
                              : "") +
                            (selectedPatient && selectedPatient.surname.trim()
                              ? selectedPatient.surname.trim()[0]
                              : "")}
                      </span>
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          openPatientSummaryPage(
                            selectedPatient.id,
                            selectedPatient.firstName
                              ? selectedPatient.firstName +
                                  " " +
                                  selectedPatient.surname
                              : ""
                          );
                        }}
                      >
                        {selectedPatient && selectedPatient.firstName
                          ? selectedPatient.firstName +
                            " " +
                            selectedPatient.surname
                          : ""}
                      </a>
                      <p>
                        {selectedPatient.dateOfBirth
                          ? displayAge(selectedPatient.dateOfBirth)
                          : ""}
                      </p>
                    </div>
                    <div className="summaryInfo patients-info-wrap">
                      <ul>
                        <li>
                          <p>Phone no:</p>
                          <h6>
                            {selectedPatient && selectedPatient.homePhone
                              ? selectedPatient.homePhone
                              : "-"}
                          </h6>
                        </li>
                        <li>
                          <p>Address:</p>
                          <h6>
                            {selectedPatient && selectedPatient.address
                              ? selectedPatient.address.addressLine1 ||
                                selectedPatient.address.addressLine2 ||
                                selectedPatient.address.addressLine3
                                ? (selectedPatient.address.addressLine1
                                    ? selectedPatient.address.addressLine1 + " "
                                    : "") +
                                  (selectedPatient.address.addressLine2
                                    ? selectedPatient.address.addressLine2 + " "
                                    : "") +
                                  (selectedPatient.address.addressLine3
                                    ? selectedPatient.address.addressLine3 + " "
                                    : "")
                                : "-"
                              : "-"}
                          </h6>
                        </li>
                        <li>
                          {/* <p>Public:</p> */}
                          <p>Medical card no.:</p>
                          <h6>
                            {/* Medical card no.{" "} */}
                            {selectedPatient.medicalCardNumber
                              ? selectedPatient.medicalCardNumber
                              : "-"}
                          </h6>
                        </li>
                        <li>
                          <p>Upcoming appointments:</p>
                          <h6>
                            {selectedPatient.nextApppointment
                              ? selectedPatient.nextApppointment
                              : "-"}
                          </h6>
                        </li>
                        <li>
                          <p>Reason for visit:</p>
                          <span className="note-count-wrap position-relative">
                            {selectedAppointment &&
                            selectedAppointment.reasonDescription ? (
                              <ParagraphWithHover
                                text={selectedAppointment.reasonDescription}
                              />
                            ) : (
                              "-"
                            )}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="clinicalNotesect">
                    <div className="title-wrap d-flex align-items-center justify-content-between">
                      <h3>Recent consultations:</h3>
                    </div>
                    <ConsultationsTable
                      patientIdVal={selectedPatient.id}
                    ></ConsultationsTable>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <Tasks />
        </div>
        <div className="versionData">Kinesin version: 1.0-117-2025-02-14</div>
      </div>

      <AddAppointmentModal
        headerTitle={"Add Appointment"}
        selectedDate={selectedDateForAppointment}
        staffId={staffMemberId}
        setStaffId={setStaffMemberId}
        practiceServices={practiceServices}
        startDate={startDateForAppointment}
        startTime={startTimeForAppointment}
        selectedView="day"
        homePageRefreshAppointment={getbystaffmemberid}
      />
    </>
  );
};

export default AlliedHome;
