import dayjs from "dayjs";

export const mapMedications = (medications, selectedRows) => {
  return (
    medications
      ?.filter((medication) => selectedRows[medication.id])
      .map((medication) => ({
        dosage: medication.dosage ?? "",
        form: medication.form ?? "",
        startDate: dayjs().startOf("day").format("DD-MM-YYYY"),
        finishDate: dayjs().startOf("day").format("DD-MM-YYYY"),
        duration: medication.duration?.toString() ?? "",
        durationFormat: medication.durationFormat ?? "",
        quantity: medication.quantity?.toString() ?? "",
        usageType: medication.acuteLongTerm ?? "",
        repeat: medication.repeat ?? "",
        paymentType: "",
        isManual: false,
        tradeName: medication.tradeName ?? "",
        manufacturer: medication.manufacturer ?? "",
        packSize: medication.pack ?? "",
        medicineId: medication.medicationId,
        route: medication.route ?? "",
        frequency: medication.frequency ?? "",
      })) ?? []
  );
};

const formatDate = (date) => {
  if (!date) return "";
  const [year, month, day] = date.split("-");
  return `${day}-${month}-${year}`;
};
