import React, { useState } from "react";
import NavigationBar from "../../PatientDetailGp/components/NavigationBar/NavigationBar.jsx";
import PatientCard from "../PatientCard/PatientCard.jsx";
import EmptyState from "./EmptyState/EmptyState.jsx";
import PaymentMenu from "../../Payments/PaymentMenu/PaymentMenu";

import s from "./PatientPayments.module.css";

import History from "../../../../assets/images/History.svg";
import FamilyHistory from "../../../../assets/images/apple-wallet.svg";
// import Card from "../../../../assets/images/mastercard-card.svg";
import { PatientDuePaymentsTable } from "./PatientDuePaymentsTable/PatientDuePaymentsTable.jsx";
import { PatientPaymentsTable } from "./PatientPaymentsTable/PatientPaymentsTable.jsx";
import { FamilyPaymentsTable } from "./FamilyPaymentsTable/FamilyPaymentsTable.jsx";

const menuLinks = [
  { text: "Payment history", icon: `${History}` },
  { text: "Payment due history", icon: `${History}` },
  {
    text: "Family payment history",
    icon: `${FamilyHistory}`,
  },
  //   { text: "Card details", icon: `${Card}` },
];

const PatientPayments = () => {
  const [activeItem, setActiveItem] = useState(menuLinks[0]);

  return (
    <>
      <NavigationBar />
      <div style={{ margin: "0 auto" }}>
        <div className={s.wrapper}>
          <div className={s.firstColumnWrapper}>
            <>
              <PatientCard />
              <PaymentMenu
                items={menuLinks}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
              />
            </>
          </div>
          <div className={s.secondColumnWrapper}>
            <div className={s.secondColumnSubWrapper}>
              <div className={s.contentWrapper}>
                {activeItem.text === "Payment history" && (
                  <PatientPaymentsTable />
                )}
                {activeItem.text === "Payment due history" && (
                  <PatientDuePaymentsTable />
                )}
                {activeItem.text === "Family payment history" && (
                  <FamilyPaymentsTable />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientPayments;
