import styles from "./FamilyPaymentsTable.module.css";
import { Toaster } from "react-hot-toast";
import { Loader2 } from "lucide-react";
import { FamilyPaymentsTableItem } from "./FamilyPaymentsTableItem";
import { useFamilyPayments } from "../../../../../hooks/useFamilyPayments";
import EmptyState from "../EmptyState/EmptyState";

export const FamilyPaymentsTable = () => {
  const { data, isLoading } = useFamilyPayments();

  if (data?.length === 0 && !isLoading) {
    return <EmptyState />;
  }

  const renderTableContent = () => {
    if (isLoading) {
      return (
        <div className={styles.emptyState}>
          <Loader2 className={styles.loadingSpinner} size={24} />
          <p className={styles.emptyStateTitle}>Loading data...</p>
          <p className={styles.emptyStateText}>
            Please wait while we fetch the payments.
          </p>
        </div>
      );
    }

    if (data?.length === 0) {
      return (
        <div className={styles.emptyState}>
          <p className={styles.emptyStateTitle}>No items found</p>
          <p className={styles.emptyStateText}>
            There are no items to display at this time.
          </p>
        </div>
      );
    }

    return (
      <ul className={styles.tableItems}>
        {data?.map((item) => (
          <FamilyPaymentsTableItem key={item.id} item={item} />
        ))}
      </ul>
    );
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <h2 className={styles.tableTitle}>Payments</h2>
      <div className={styles.tableWarpper}>
        <div className={styles.table}>
          <div className={styles.tableHeader}>
            <p id="report-table-due-date">Due date</p>
            <p id="report-table-service-name">Service Name</p>
            <p id="report-table-amount-owed">Amount owed</p>
          </div>
          {renderTableContent()}
        </div>
      </div>
    </>
  );
};
