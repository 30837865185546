import { useMainService } from "../../../../../hooks/useMainService";
import { ItemMenuButton } from "./ItemMenuButton";
import styles from "./PatientDuePaymentsTable.module.css";

export const PatientDuePaymentsTableItem = ({ item }) => {
  const { data: mainService } = useMainService(
    item?.consultation?.mainPracticeServiceId
  );

  return (
    <li className={styles.tableItem}>
      <p aria-describedby="report-table-due-date">{item?.dueDate || "-"}</p>
      <p aria-describedby="report-table-service-name">
        {mainService?.name || "-"}
      </p>
      <p aria-describedby="report-table-amount-owed">€{item?.amountDue ?? 0}</p>
      <ItemMenuButton id={item.id} />
    </li>
  );
};
