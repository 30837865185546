import React, { useState } from "react";
import s from "./PatientSms.module.css";
import { Toaster } from "react-hot-toast";
import CustomButton from "../../../../components/helpers/CustomButton/CustomButton";
import Plus from "../../../../assets/images/transparrent-plus.svg";
import { Empty, Table } from "antd";
import srcicon from "../../../../assets/images/srcicon.svg";
import filter from "../../../../assets/images/filter.svg";

const PatientsSmsTable = ({ smsList, handleClick, patient }) => {
  const columns = [
    {
      title: "Date Sent",
      dataIndex: "created",
      key: "created",
    },
    {
      title: "Mobile #",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
  ];

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className={s.topWrapper}>
      <Toaster position="top-center" reverseOrder={false} />
      <div style={{ width: "100%" }}>
        <div style={{ width: "100%" }}>
          <div className={s.titleWrapper}>
            <h3 className={s.title}>
              {`Messages sent to ${patient.firstName} ${patient.surname}`}
            </h3>
          </div>
          <div
            className="d-flex align-items-center allied-documents-wrap patientsheading"
            style={{ float: "right", marginBottom: "20px" }}
          >
            <div className="search_bar">
              <input
                type="search"
                className="form-control"
                placeholder="Search messages..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <img src={srcicon} alt="" />
              <img className="filterIcon" src={filter} alt="" />
            </div>
            <div style={{ float: "right" }}>
              <CustomButton
                title="Send Sms"
                buttonHandle={handleClick}
                icon={Plus}
                buttonClassName={s.addButton}
              />
            </div>
          </div>
        </div>
        <div className="custom-table-wrapper patients-table">
          <Table
            dataSource={smsList.filter((sms) =>
              sms.message.toLowerCase().includes(searchQuery.toLowerCase())
            )}
            columns={columns}
            locale={{
              emptyText:
                !smsList ||
                (smsList.length === 0 && (
                  <Empty description="No Records Found" />
                )),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PatientsSmsTable;
