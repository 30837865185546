import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Loader2 } from "lucide-react";
import moment from "moment";

import styles from "./PatientDetailCardWithOverview.module.css";
import { calculateAge, getInitials } from "./utils.js";
import CustomButton from "../../components/helpers/CustomButton/CustomButton.jsx";
import pencil from "../../assets/images/edit-green-pencil.svg";
import PrinterIcon from "../../assets/images/printer-green.svg";

import Modal from "../../components/helpers/Modal/Modal.jsx";
import EditModalForm from "../../view/pages/PatientDetailGp/components/EditModalForm/EditModalForm.jsx";
import SmsModal2 from "../../components/Modal/SmsModal2.jsx";
import { PatientOverviewModal } from "./components/PatientOverviewModal/PatientOverviewModal.jsx";
import { usePatientData } from "../../hooks/usePatientData.js";
import { useUpdatePatient } from "../../hooks/useUpdatePatient";
import { AddTaskModal } from "../TaskModal/AddTaskModal.jsx";
import PDFDisplayModal from "../Modal/PDFDisplayModal/PDFDisplayModal.jsx";

const PatientDetailCardWithOverview = () => {
  const patientId = localStorage.getItem("kinesin-patientId");
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSmsModalOpen, setIsSmsModalOpen] = useState(false);
  const [patientCopy, setPatientCopy] = useState({});

  const { data: patient, isPending: isLoading } = usePatientData({
    onSuccess: (data) => {
      setPatientCopy(data);
    },
  });

  useEffect(() => {
    setPatientCopy(patient);
  }, [patient]);

  const updatePatient = useUpdatePatient(patientId);

  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);

  if (isLoading) {
    return (
      <div className={`${styles.patientDetailCard} bg`}>
        <div className={styles.emptyState}>
          <Loader2 className={styles.loadingSpinner} size={24} />
          <p className={styles.emptyStateTitle}>Loading data...</p>
          <p className={styles.emptyStateText}>
            Please wait while we fetch the patient data.
          </p>
        </div>
      </div>
    );
  }

  const age = calculateAge(patient.dateOfBirth);
  const { city, addressLine1, addressLine2, postCode } = patient.address;

  const handleModalSave = async (e) => {
    e.preventDefault();
    updatePatient.mutate(patientCopy, {
      onSuccess: () => {
        setIsModalOpen(false);
      },
    });
  };

  return (
    <div className={`${styles.patientDetailCard} bg`}>
      <div className={styles.patientHeaderWrap}>
        <div className="d-flex align-items-center">
          <div className={`${styles.patientProfileImgWrap} flex-shrink-0`}>
            {patient.avatarUrl ? (
              <img
                src={patient.avatarUrl}
                alt="Patient Avatar"
                className={styles.userProfileImage}
              />
            ) : (
              <span className={styles.userProfileImage}>
                {getInitials(`${patient.firstName} ${patient.surname}`)}
              </span>
            )}
          </div>
          <div className={styles.patientHeaderContentWrap}>
            <div className={styles.patientName}>
              <p
                title={patient.firstName}
                className={`text-ellipsis ${styles.patientNameSurname}`}
                style={{ fontSize: "23px" }}
              >
                {patient.firstName} {patient.surname} ({age})
              </p>
            </div>
            <p style={{ width: "100%" }}>
              DOB : {moment(patient.dateOfBirth).format("DD-MM-YYYY")}{" "}
              <span style={{ color: "#ef0064", paddingLeft: "20px" }}>
                {" "}
                {patient.died ? "DECEASED" : ""}
              </span>
            </p>
            <div className={styles.patientOverviewTitle}>
              {/* <PatientOverviewModal /> */}
              <div className={styles.additionalBtnsWarpper}>
                <PatientOverviewModal />
                <CustomButton
                  buttonClassName={styles.actionBtn}
                  buttonHandle={() => setIsSmsModalOpen(true)}
                  title={"Send SMS"}
                  icon={false}
                />
                <AddTaskModal patient={patientCopy} buttonText={"Add Task"} />
              </div>
            </div>
          </div>

          <div className="d-flex ms-sm-auto mt-md-0 align-self-start modal-btn-wrap gap-3">
            <CustomButton
              buttonClassName={styles.editBtn}
              title={"Edit"}
              icon={pencil}
              buttonHandle={() => setIsModalOpen(true)}
            />

            <CustomButton
              buttonClassName={styles.editBtn}
              title="Print"
              icon={PrinterIcon}
              buttonHandle={() => setIsPdfModalOpen(true)}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.patientBottomWrap} d-flex flex-wrap`}>
        <div className={styles.patientLeftDescription}>
          <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} d-inline-block`}>
              Phone no:
            </span>
            <h6 className={`${styles.h6} ${styles.textStratos} mb-0`}>
              {patient.mobilePhone ? patient.mobilePhone : patient.homePhone}
            </h6>
          </div>

          <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} fw-500 d-inline-block`}>
              Address:
            </span>
            <h6 className={`${styles.h6} ${styles.textStratos} fw-500 mb-0 `}>
              {addressLine2 ? addressLine2 : addressLine1},{" "}
              {city && `${city} ${postCode}`}
            </h6>
          </div>

          <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} fw-500 d-inline-block`}>
              Public:
            </span>
            <h6
              className={`${styles.h6} ${styles.textStratos} fw-500 mb-0 ${styles.textEllipsis}`}
            >
              Medical card no. {patient.medicalCardNumber}
            </h6>
          </div>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.patientRightDescription}>
          <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} fw-500 d-inline-block`}>
              Opportunities:
            </span>
            <h6 className={`${styles.h6} ${styles.textStratos} fw-500 mb-0`}>
              -
            </h6>
          </div>
          <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} fw-500 d-inline-block`}>
              Upcoming appointments:
            </span>
            <h6 className={`${styles.h6} ${styles.textStratos} fw-500 mb-0`}>
              {patient.nextApppointment
                ? moment(patient.nextApppointment).format("DD MMMM YYYY")
                : "N/A"}
            </h6>
          </div>
          <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} fw-500 d-inline-block`}>
              Registered doctor:
            </span>
            <h6 className={`${styles.h6} ${styles.textStratos} fw-500 mb-0`}>
              {patient.doctorDetails
                ? patient.doctorDetails.firstName +
                  " " +
                  patient.doctorDetails.lastName
                : "Not set"}
            </h6>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <Modal
          title={"Edit profile"}
          height="90%"
          overflowY="auto"
          onClose={() => setIsModalOpen(false)}
          onSave={handleModalSave}
        >
          <EditModalForm
            patient={patientCopy}
            handleInputChange={(field, value) => {
              setPatientCopy((prev) => ({ ...prev, [field]: value }));
            }}
            handleInputAddressChange={(field, value) => {
              setPatientCopy((prev) => ({
                ...prev,
                address: {
                  ...prev.address,
                  [field]: value,
                },
              }));
            }}
            handleInputDoctorChange={(e) => {
              setPatientCopy((prev) => ({
                ...prev,
                doctorDetailsId: e.target.value,
              }));
            }}
          />
        </Modal>
      )}

      {isSmsModalOpen && (
        <Modal
          onClose={() => setIsSmsModalOpen(false)}
          title="Send sms"
          hideCancel={true}
        >
          <SmsModal2
            tenantVal={tenantVal}
            patient={patient}
            onClose={() => setIsSmsModalOpen(false)}
          />
        </Modal>
      )}

      <PDFDisplayModal
        open={isPdfModalOpen}
        onClose={() => setIsPdfModalOpen(false)}
      />
    </div>
  );
};

export default PatientDetailCardWithOverview;
