import { AiOutlineCheckCircle } from "react-icons/ai";
import { RiErrorWarningLine } from "react-icons/ri";
import React, { useState, useEffect } from "react";
import CardWithTitle from "../../../../../components/helpers/CardWithTitle/CardWithTitle.jsx";
import Pencil from "../../../../../assets/images/edit-green-pencil.svg";
import ErrorIcon from "../../../../../assets/images/consent-alert-icon.svg";
import Modal from "../../../../../components/helpers/Modal/Modal.jsx";
import CustomCheckbox from "../../../../../components/helpers/CustomCheckbox/CustomCheckbox.jsx";
import s from "./Consent.module.css";
import {
  GET_PATIENT_CONSENT_BY_ID,
  POST_PATIENT_CONSENT,
} from "../../../../../api/Service.js";
import { useSelector } from "react-redux";
import { getData, postData } from "../../../../../components/API/index.js";

const Consent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [consentData, setConsentData] = useState([]);
  const [formState, setFormState] = useState({
    sms: false,
    email: false,
    post: false,
    phone: false,
  });

  // MAIN VARIABLES
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const patientId = localStorage.getItem("kinesin-patientId");

  // FETCHING DATA
  const fetchConsent = async () => {
    try {
      const response = await getData(
        `${tenantVal}${GET_PATIENT_CONSENT_BY_ID}${patientId}`
      );
      const data = response[response.length - 1];
      console.log("Consent response", response);
      setConsentData(response);
      setFormState({
        sms: data.sms,
        email: data.email,
        post: data.post,
        phone: data.phone,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchConsent();
  }, [patientId]);

  // HANDLERS
  const handleOpenModal = async () => {
    await fetchConsent();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (name) => (event) => {
    const { checked } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const formData = {
      id:
        consentData.length > 0 ? consentData[consentData.length - 1].id : null,
      patientId,
      sms: formState.sms ?? false,
      email: formState.email ?? false,
      post: formState.post ?? false,
      phone: formState.phone ?? false,
    };
    try {
      const response = await postData(
        `${tenantVal}${POST_PATIENT_CONSENT}`,
        formData
      );
      console.log("Server Consent Response:", response);
      setIsModalOpen(false);
      fetchConsent();
    } catch (error) {
      console.error("There was a problem with the axios operation:", error);
    }
  };

  //render last arr element for demonstration only, need PUT endpoint for edit
  const consentlInfo =
    consentData.length > 0 ? consentData[consentData.length - 1] : {};

  const data = [
    {
      label: "Sms",
      icon: consentlInfo.sms ? (
        <AiOutlineCheckCircle className={s.checkIcon} />
      ) : (
        <img src={ErrorIcon} alt="" className={s.errorIcon} />
      ),
    },
    {
      label: "Email",
      icon: consentlInfo.email ? (
        <AiOutlineCheckCircle className={s.checkIcon} />
      ) : (
        <img src={ErrorIcon} alt="" className={s.errorIcon} />
      ),
    },
    {
      label: "Phone",
      icon: consentlInfo.phone ? (
        <AiOutlineCheckCircle className={s.checkIcon} />
      ) : (
        <img src={ErrorIcon} alt="" className={s.errorIcon} />
      ),
    },
    {
      label: "Post",
      icon: consentlInfo.post ? (
        <AiOutlineCheckCircle className={s.checkIcon} />
      ) : (
        <img src={ErrorIcon} alt="" className={s.errorIcon} />
      ),
    },
  ];

  return (
    <>
      <CardWithTitle
        title={"Consent"}
        buttonTitle="Edit"
        icon={Pencil}
        marginBottom={"22px"}
        className={s.editButton}
        showButton={false}
        onButtonClick={handleOpenModal}
      >
        <ul className={s.listContainer}>
          {data.map((item, index) => (
            <li key={index} className={s.listItemContainer}>
              <p className={s.listItemTitle}>{item.label}:</p>
              <p className={s.listItemDescr}>{item.value}</p>
              {item.icon}
            </li>
          ))}
        </ul>
        {isModalOpen && (
          <Modal
            width="500px"
            title="Consent"
            onClose={handleCloseModal}
            onSave={handleSave}
          >
            <form className={s.form} onSubmit={handleSave}>
              <div className={s.inputWrapper}>
                <p className={s.rowTitle}>Sms:</p>

                <CustomCheckbox
                  checked={formState.sms}
                  handleChange={handleCheckboxChange("sms")}
                />
              </div>
              <div className={s.inputWrapper}>
                <p className={s.rowTitle}>Email:</p>
                <CustomCheckbox
                  checked={formState.email}
                  handleChange={handleCheckboxChange("email")}
                />
              </div>
              <div className={s.inputWrapper}>
                <p className={s.rowTitle}>Phone:</p>
                <CustomCheckbox
                  checked={formState.phone}
                  handleChange={handleCheckboxChange("phone")}
                />
              </div>
              <div className={s.inputWrapper}>
                <p className={s.rowTitle}>Post:</p>
                <CustomCheckbox
                  checked={formState.post}
                  handleChange={handleCheckboxChange("post")}
                />
              </div>
            </form>
          </Modal>
        )}
      </CardWithTitle>
    </>
  );
};

export default Consent;
