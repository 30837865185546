import React from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header/Header";
import Home from "./view/pages/LogInAuthentication/LogIn";
import PickService from "./view/pages/AppointmentPickService/PickService";
import AppoinmentStepFour from "./view/pages/AppointmentPickService/AppoinmentStepFour";
import PickMedicalProfessional from "./view/pages/AppointmentPickService/PickMedicalProfessional";
import PickDate from "./view/pages/AppointmentPickService/PickDate";
import Payement from "./view/pages/AppointmentPickService/Payement";
import MyPatients from "./view/pages/MyPatients/MyPatients";
import PatientInfo from "./view/pages/AppointmentPickService/PatientInfo";
import Scheduler from "./view/app/alliedappointment/Scheduler";
import AlliedPatient from "./view/app/alliedappointment/Patient";
import "./assets/css/responsive.css";
import AlliedPatientMain from "./view/app/alliedappointment/AlliedPatient";
import AlliedSms from "./view/app/alliedappointment/AlliedSms";
import AlliedTask from "./view/app/alliedappointment/AlliedTask";
import AlliedDocuments from "./view/app/alliedappointment/AlliedDocuments";
import AddPatient from "./view/app/alliedappointment/AddPatient";
import PracticeDetails from "./view/app/alliedsettings/PracticeDetails";
import UserDetails from "./view/app/alliedsettings/UserDetails";
import ServiceDetails from "./view/app/alliedsettings/ServiceDetails";
import PracticeHoursDetails from "./view/app/alliedsettings/PracticeHoursDetails";
import StaffHoursDetails from "./view/app/alliedsettings/StaffHoursDetails";
import NotificationDetails from "./view/app/alliedsettings/NotificationDetails";
import PayementDetails from "./view/app/alliedsettings/PayementDetails";
import Profile from "./components/NavMenu/Profile";
import SettingsRoute from "./components/Route/SettingsRoute";
import TimeOff from "./components/NavMenu/TimeOff";
import AlliedHome from "./view/app/alliedhome/AlliedHome";
import GpHome from "./view/pages/Gp/GpHome";

import PrivateRoute from "./route/ProtectedRoute";
import SmsSettngs from "./view/app/alliedsettings/SmsSettngs";
import PolicyDetails from "./view/app/alliedsettings/PolicyDetails";
import ProtocolDetails from "./view/app/alliedsettings/ProtocolDetails";
import PatientDetail from "./view/pages/PatientDetail/PatientDetail";
import Appointments from "./view/pages/Appointment/Appointment";
import Reports from "./view/pages/Reports/Reports.jsx";

import "./App.css";
import Sidebar from "./components/Sidebar/Sidebar";
import PatientDetailGp from "./view/pages/PatientDetailGp/PatientDetailGp.js";
import PatientDocumentsGp from "./view/pages/PatientGp/PatientDocumentsGp/PatientDocumentsGp.js";
import PatientCommunication from "./view/pages/PatientGp/PatientCommunication/PatientCommunication.jsx";
import PatientPayments from "./view/pages/PatientGp/PatientPayments/PatientPayments.js";
import PatientFamily from "./view/pages/PatientGp/PatientFamily/PatientFamily.js";
import PatientResult from "./view/pages/PatientGp/PatientResults/PatientResults";
import PatientImmunizations from "./view/pages/PatientGp/PatientImmunizations/PatientImmunizations";
import PatientMedication from "./view/pages/PatientGp/PatientMedication/PatientMedication";
import PatientConsult from "./view/pages/PatientGp/PatientConsult/PatientConsult";
import PatientProtocols from "./view/pages/PatientGp/PatientProtocols/PatientProtocols";
import PatientPregnancy from "./view/pages/PatientGp/PatientPregnancy/PatientPregnancy";
import ConsultationNoteTemplates from "./view/app/alliedsettings/ConsultationNoteTemplates";
import HeaderFooter from "./view/app/alliedsettings/HeaderFooter";
import PatientConsultations from "./view/pages/PatientGp/PatientConsultations/PatientConsultations.js";
import MyAppointments from "./view/pages/MyAppointments/MyAppointments.jsx";
import ConsultationEditPage from "./view/pages/ConsultationEditPage/ConsultationEditPage.jsx";

const RouteView = () => {
  const isAuthenticated = localStorage.getItem("kinesin-isAuthenticated");

  if (isAuthenticated) {
    sessionStorage.setItem(
      "accessToken",
      localStorage.getItem("kinesin-accessToken")
    );
    sessionStorage.setItem(
      "refreshToken",
      localStorage.getItem("kinesin-refreshToken")
    );
    sessionStorage.setItem("userId", localStorage.getItem("kinesin-userId"));
    sessionStorage.setItem(
      "requestId",
      localStorage.getItem("kinesin-requestId")
    );
  }

  return (
    <div className="App">
      {!isAuthenticated && <Header />}

      <div className="dashboard">
        <Sidebar />
        <div className="main-content">
          <Header />
          <div className="main-content-wrapper">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/pick-service" element={<PickService />} />
              <Route
                path="/pick-professional"
                element={<PickMedicalProfessional />}
              />
              <Route path="/edit-doc-info" element={<AppoinmentStepFour />} />
              <Route path="/pick-date" element={<PickDate />} />
              <Route path="/patient-info" element={<PatientInfo />} />
              <Route path="/mypatients" element={<MyPatients />} />
              <Route path="/payement" element={<Payement />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/reports/:reportType" element={<Reports />} />

              <Route
                path="/appointment"
                element={
                  <PrivateRoute>
                    <Scheduler />
                  </PrivateRoute>
                }
              />
              <Route
                path="/patient"
                element={
                  <PrivateRoute>
                    <AlliedPatient />
                  </PrivateRoute>
                }
              />

              <Route
                path="/allied_home"
                element={
                  <PrivateRoute>
                    <AlliedHome />
                  </PrivateRoute>
                }
              />
              <Route
                path="/home"
                element={
                  <PrivateRoute>
                    <GpHome />
                  </PrivateRoute>
                }
              />
              <Route
                path="/allied_patient"
                element={
                  <PrivateRoute>
                    <AlliedPatientMain />
                  </PrivateRoute>
                }
              />
              <Route
                path="/allied_sms"
                element={
                  <PrivateRoute>
                    <AlliedSms />
                  </PrivateRoute>
                }
              />
              <Route
                path="/allied_task"
                element={
                  <PrivateRoute>
                    <AlliedTask />
                  </PrivateRoute>
                }
              />
              <Route path="/allied_documents" element={<AlliedDocuments />} />
              <Route path="/add_patient" element={<AddPatient />} />
              <Route path="/edit_patient" element={<AddPatient />} />
              <Route
                path="/settings"
                element={
                  <PrivateRoute>
                    <SettingsRoute />
                  </PrivateRoute>
                }
              />
              <Route
                path="/practice_details"
                element={
                  <PrivateRoute>
                    <PracticeDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/protocols_details"
                element={
                  <PrivateRoute>
                    <ProtocolDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/user_details"
                element={
                  <PrivateRoute>
                    <UserDetails />
                  </PrivateRoute>
                }
              />
              {/* <Route
          element={
            <PrivateRoute>
              <Suspense>
                <userDetailsPrev/>
              </Suspense>
            </PrivateRoute>
          }
        /> */}
              <Route path="/service_details" element={<ServiceDetails />} />
              <Route
                path="/practice_hours_details"
                element={<PracticeHoursDetails />}
              />
              <Route
                path="/staff_hours_details"
                element={
                  <PrivateRoute>
                    <StaffHoursDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/sms_settings_details"
                element={
                  <PrivateRoute>
                    <SmsSettngs />
                  </PrivateRoute>
                }
              />
              <Route
                path="/consultation_templates"
                element={
                  <PrivateRoute>
                    <ConsultationNoteTemplates />
                  </PrivateRoute>
                }
              />
              <Route
                path="/notifications_details"
                element={
                  <PrivateRoute>
                    <NotificationDetails />
                  </PrivateRoute>
                }
              />

              <Route
                path="/headersandfooters"
                element={
                  <PrivateRoute>
                    <HeaderFooter />
                  </PrivateRoute>
                }
              />
              <Route
                path="/payments_details"
                element={
                  <PrivateRoute>
                    <PayementDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/policy_details"
                element={
                  <PrivateRoute>
                    <PolicyDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                }
              />
              <Route
                path="/timeoff"
                element={
                  <PrivateRoute>
                    <TimeOff />
                  </PrivateRoute>
                }
              />
              <Route
                path="/patient-detail-allied"
                element={
                  <PrivateRoute>
                    <PatientDetail />
                  </PrivateRoute>
                }
              />
              <Route path="/patient-detail" element={<PatientDetailGp />} />
              <Route path="/patient-detail-gp" element={<PatientDetailGp />} />
              <Route path="/patient-pregnancy" element={<PatientPregnancy />} />
              <Route
                path="/patient-documents-gp"
                element={<PatientDocumentsGp />}
              />
              <Route
                path="/patient-communication"
                element={<PatientCommunication />}
              />
              <Route path="/patient-payments" element={<PatientPayments />} />
              <Route path="/patient-family" element={<PatientFamily />} />
              <Route path="/patient-consult" element={<PatientConsult />} />
              <Route
                path="/patient-consultations"
                element={<PatientConsultations />}
              />

              <Route path="/patient-results" element={<PatientResult />} />
              <Route
                path="/patient-immunizations"
                element={<PatientImmunizations />}
              />
              <Route
                path="/patient-medication"
                element={<PatientMedication />}
              />
              <Route path="/patient-protocols" element={<PatientProtocols />} />

              <Route path="/myappointments" element={<MyAppointments />} />

              <Route
                path="/appointments"
                element={
                  <PrivateRoute>
                    <Appointments />
                  </PrivateRoute>
                }
              />
              <Route
                path="consultation/edit/:consultationId"
                element={
                  <PrivateRoute>
                    <ConsultationEditPage />
                  </PrivateRoute>
                }
              />
            </Routes>
          </div>
        </div>
      </div>
      {!isAuthenticated && <Footer />}
    </div>
  );
};

export default RouteView;
