import React from "react";
import NavigationBar from "./components/NavigationBar/NavigationBar.jsx";

import s from "./PatientDeteilGp.module.css";
import AllergiesIntolerance from "./components/AllergiesIntolerance/AllergiesIntolerance.jsx";
import FamilyMedicalHistory from "./components/FamilyMedicalHistory/FamilyMedicalHistory.jsx";
import Social from "./components/Social/Social.jsx";
import Preferences from "./components/Preferences/Preferences.jsx";
import BaselineDetails from "./components/BaselineDetails/BaselineDetails.jsx";
import HealthIdentifiers from "./components/HealthIdentifiers/HealthIdentifiers.jsx";
import Consent from "./components/Consent/Consent.jsx";
import { MedicalHistoryCard } from "../../../components/PatientDetailCardWithOverview/components/PatientOverviewModal/components/MedicalHistory/MedicalHistoryCard";
import { usePatientData } from "../../../hooks/usePatientData.js";
import PatientDetailCardWithOverview from "../../../components/PatientDetailCardWithOverview/PatientDetailCardWithOverview.jsx";

const PatientDetailGp = () => {
  const { data: patient } = usePatientData();

  return (
    <>
      <NavigationBar />
      <div style={{ margin: "0 auto" }}>
        <div className={s.wrapper}>
          <div className={s.firstColumnWrapper}>
            <PatientDetailCardWithOverview />
            <AllergiesIntolerance />
            <FamilyMedicalHistory />
          </div>
          <div className={s.secondColumnWrapper}>
            <MedicalHistoryCard />
            <div className={s.secondColumnSubWrapper}>
              <Social />
              <Preferences />
            </div>
            <div className={s.thirdColumnWrapper}>
              <BaselineDetails />
              <HealthIdentifiers patient={patient} />
              <Consent />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientDetailGp;
