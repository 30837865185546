import style from "./ConsultationBox.module.css";
import dayjs from "dayjs";
import { FileText, Trash } from "lucide-react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { Image } from "react-bootstrap";

import Modal from "../../../../../../components/helpers/Modal/Modal.jsx";
import DocIcon from "../../../../../../assets/images/doc-icon.svg";
import { StartConsultationForm } from "../StartConsultationForm/StartConsultationForm";
import CustomButton from "../../../../../../components/helpers/CustomButton/CustomButton";
import { usePracticeServices } from "../../../../../../hooks/usePracticeServices";
import { useConsultationServices } from "../../../../../../hooks/useConsultationServices.js";
import { useConsultationNote } from "../../../../../../hooks/useConsultationNote.js";
import { useFinishConsultation } from "../../../../../../hooks/useFinishConsultation";
import { useAddConsultationService } from "../../../../../../hooks/useAddConsultationService";
import { useDeleteConsultationService } from "../../../../../../hooks/useDeleteConsultationService";
import { useMainService } from "../../../../../../hooks/useMainService";
import { SaveTemplateModal } from "./components/SaveTemplateModal";
import { useSaveTemplate } from "../../../../../../hooks/useSaveTemplate.js";
import { useConfirmPayment } from "../../../../../../hooks/useConfirmPayment";
import { useChangeConsulationMainService } from "../../../../../../hooks/useChangeConsulationMainService";

export const ConsultationBox = ({
  patient,
  staffName,
  consultation,
  saveAsTemplate,
  setSaveAsTemplate,
  onFinish,
}) => {
  const isPaid = consultation?.moneyOwed === false;
  const hasAppointment = patient && patient?.patient?.nextApppointment;
  const [service, setService] = useState("Select Service");
  const [isModalOpen, setIsModalOpen] = useState({ open: false, id: null });
  const [showSaveTemplate, setShowSaveTemplate] = useState(false);

  const hasActiveConsultation = !!consultation;

  const { data: consultationNote } = useConsultationNote(consultation?.id);
  const { data: mainService } = useMainService(
    consultation?.mainPracticeServiceId
  );

  const { data: services, isLoading: isServicesLoading } =
    usePracticeServices();
  const { data: consultationServices, isLoading: isPaymentsLoading } =
    useConsultationServices(consultation?.id);

  const finishConsultation = useFinishConsultation(
    consultation?.id,
    patient?.id,
    consultation
  );
  const addConsultationService = useAddConsultationService(consultation?.id);
  const deleteConsultationService = useDeleteConsultationService(
    consultation?.id
  );
  const saveTemplate = useSaveTemplate();
  const confirmPaymentMutation = useConfirmPayment(consultation?.id);
  const changeMainService = useChangeConsulationMainService(consultation);

  const allConultationServices = [
    mainService ? { service: mainService, id: mainService?.id } : null,
    ...(consultationServices ?? []),
  ].filter(Boolean);

  const handleFinishConsultation = () => {
    if (!consultationNote || consultationNote?.length === 0) {
      toast.error("Please add consultation note before finishing");
      return;
    }

    if (saveAsTemplate) {
      setShowSaveTemplate(true);
    } else {
      onFinish();
      finishConsultation.mutate();
    }
  };

  const handleSaveTemplate = async (templateName) => {
    try {
      await saveTemplate.mutateAsync({
        name: templateName,
        note: consultationNote?.at(0)?.note,
      });

      finishConsultation.mutate();
      onFinish();
      setSaveAsTemplate(false);
      setShowSaveTemplate(false);
    } catch (error) {
      console.error("Failed to save template:", error);
    }
  };

  const handleConfirmPayment = () => {
    confirmPaymentMutation.mutate();
  };

  return (
    <>
      <div className={style.consultationBoxWrapper}>
        <section className={style.consultationBox}>
          <div className={style.consultationBoxTop}>
            <div className={style.consultationBoxTopItem}>
              <p>
                {hasActiveConsultation
                  ? "Current Consultation"
                  : "Planned consultation"}
              </p>
              <p>
                {hasActiveConsultation
                  ? dayjs(consultation?.created, "DD-MM-YYYY HH:mm").format(
                      "DD MMM YYYY HH:mm"
                    )
                  : hasAppointment
                  ? patient.patient.nextApppointment
                  : "None scheduled"}
              </p>
            </div>
            <div>
              {hasActiveConsultation ? (
                <CustomButton
                  buttonHandle={() => handleFinishConsultation()}
                  title="Finish consultation"
                  buttonClassName={style.button}
                  icon={false}
                />
              ) : (
                <CustomButton
                  buttonClassName={style.button}
                  icon={false}
                  title="Start consultation"
                  buttonHandle={() => setIsModalOpen({ open: true })}
                />
              )}
            </div>
          </div>
          {hasActiveConsultation && (
            <div className={style.addServiceWrapper}>
              <select
                as="select"
                className="form-select form-control custom-select"
                value={service}
                onChange={(e) => setService(e.target.value)}
                disabled={isPaid}
              >
                <option value="Select Service">Select Service</option>
                {isServicesLoading ? (
                  <option>Loading services...</option>
                ) : (
                  (services ?? []).map((item, index) => (
                    <option value={item.id || index} key={item.id}>
                      {item.name}
                    </option>
                  ))
                )}
              </select>
              <button
                className={style.addServiceButton}
                onClick={() => {
                  if (service === "Select Service") {
                    toast.error("Please select a service");
                    return;
                  }
                  addConsultationService.mutate(service);
                }}
                disabled={isPaid}
              >
                Add additional service
              </button>
            </div>
          )}
        </section>
        <section className={style.paymentSection}>
          <h2 className={style.paymentHeader}>
            <span>Payment</span>
            <span>
              Total price: €
              {allConultationServices?.reduce(
                (acc, curr) => acc + curr?.service?.price,
                0
              ) ?? 0}
            </span>
          </h2>
          {isPaymentsLoading ? (
            <div className={style.paymentSectionLoading}>
              Loading payments...
            </div>
          ) : allConultationServices && allConultationServices.length > 0 ? (
            <>
              <ul className={style.paymentItems}>
                {allConultationServices.map((service, index) => (
                  <li key={service?.id || index} className={style.paymentItem}>
                    <div className={style.serviceInfo}>
                      <div className={style.iconWrapper}>
                        <FileText size={20} />
                      </div>
                      <span>{service?.service?.name}</span>
                    </div>
                    <div className={style.priceControls}>
                      <div className={style.priceInputWrapper}>
                        <input
                          type="text"
                          value={`€${service?.service?.price}`}
                          className="form-control"
                          readOnly
                          disabled={isPaid}
                        />
                      </div>
                      {allConultationServices?.length &&
                        allConultationServices?.length > 1 &&
                        !isPaid && (
                          <button
                            className={style.deleteButton}
                            onClick={async () => {
                              if (index === 0) {
                                await changeMainService.mutate(
                                  allConultationServices?.at(1)?.serviceId
                                );
                                await deleteConsultationService.mutate(
                                  allConultationServices?.at(1)?.id
                                );

                                return;
                              }
                              await deleteConsultationService.mutate(
                                service.id
                              );
                            }}
                            type="button"
                          >
                            <Trash size={20} />
                          </button>
                        )}
                    </div>
                  </li>
                ))}
              </ul>
              <div className={style.paymentConfirmButton}>
                <CustomButton
                  buttonHandle={() => handleConfirmPayment()}
                  title={
                    isPaid
                      ? "Paid"
                      : confirmPaymentMutation.isPending
                      ? "Processing..."
                      : "Confirm payment"
                  }
                  buttonClassName={style.button}
                  icon={false}
                  disabled={confirmPaymentMutation.isPending || isPaid}
                />
              </div>
            </>
          ) : (
            <div className={style.paymentSectionNoPayment}>
              <Image src={DocIcon} alt="category" />
              <div>
                <h3>No Payment Details Yet</h3>
                <p>
                  Payment information will appear here once the consultation
                  starts.
                </p>
              </div>
            </div>
          )}
        </section>
      </div>
      {isModalOpen.open && (
        <Modal
          title="Start Consultation"
          position="center"
          onClose={() => setIsModalOpen({ open: false })}
          hideCancel={true}
          width="570px"
        >
          <StartConsultationForm
            closeModal={() => setIsModalOpen({ open: false })}
            staffName={staffName}
          />
        </Modal>
      )}
      <SaveTemplateModal
        isOpen={showSaveTemplate}
        onClose={() => setShowSaveTemplate(false)}
        onSave={handleSaveTemplate}
        isSaving={saveTemplate.isPending}
      />
    </>
  );
};
