import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import styles from "./PatientDuePaymentsTable.module.css";

import menuIcon from "../../../../../assets/images/menu.svg";
import { useUpdateDuePaymentStatus } from "../../../../../hooks/useUpdateDuePaymentStatus";

export const ItemMenuButton = ({ id }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { mutate: updatePaymentStatus } = useUpdateDuePaymentStatus();

  return (
    <div>
      <button
        id="sortBy"
        aria-label="show menu"
        aria-controls={open ? "sortBy" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={styles.menuButton}
      >
        <img src={menuIcon} alt="open menu" />
      </button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={styles.menuItemWrapper}
      >
        <MenuItem onClick={handleClose} className={styles.menuItem}>
          <button onClick={() => updatePaymentStatus({ id, status: "PAID" })}>
            Paid
          </button>
        </MenuItem>
        <MenuItem onClick={handleClose} className={styles.menuItem}>
          <button
            onClick={() => updatePaymentStatus({ id, status: "CANCELLED" })}
          >
            Cancel
          </button>
        </MenuItem>
        <MenuItem onClick={handleClose} className={styles.menuItem}>
          <button onClick={() => updatePaymentStatus({ id, status: "WAVED" })}>
            Wawed
          </button>
        </MenuItem>
      </Menu>
    </div>
  );
};
