import styles from "./PaymentMenu.module.css";

import React from "react";

const PaymentsMenu = ({ activeItem, setActiveItem, items }) => {
  return (
    <div className={`${styles.paymentMenu} bg`}>
      {items.map((item) => (
        <div
          className={`${styles.menuItem} ${
            activeItem.text === item.text ? styles.menuItemChecked : ""
          }`}
          onClick={() => setActiveItem(item)}
        >
          <img className={styles.menuIcon} alt="" src={item.icon} />
          <b className={styles.menuText}>{item.text}</b>
        </div>
      ))}
    </div>
  );
};

export default PaymentsMenu;
