export const calculateAge = (birthDate) => {
  const today = new Date();
  const birth = new Date(birthDate);
  let age = today.getFullYear() - birth.getFullYear();
  const monthDifference = today.getMonth() - birth.getMonth();
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birth.getDate())
  ) {
    age--;
  }
  return age;
};

export const getInitials = (name) => {
  if (!name || name.split(" ").length == 0) return "";

  try {
    return name
        .split(" ")
        .map((n) => n[0].toUpperCase())
        .join("");
  } catch (err) {
    return "";
  }
};
