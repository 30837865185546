import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useFinishConsultation = (
  consultationId,
  patientId,
  consultation
) => {
  const queryClient = useQueryClient();

  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error while finishing consultation: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useMutation({
    mutationFn: async () => {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.post(`${tenantVal}/consultation`, {
        ...consultation,
        endTime: new Date().toISOString(),
        id: consultationId,
        patientId: Number(patientId),
      });
      return response.data;
    },
    onSuccess: () => {
      toast.success("Consultation finished successfully");
      queryClient.invalidateQueries();
    },
    onError: handleNetworkError,
  });
};
